<script setup lang="ts">

import { DsIcon } from '@demvsystems/design-components';
import type { RouteLocationRaw } from 'vue-router';

interface Props {
  to: RouteLocationRaw,
  title: string,
}

defineProps<Props>();
</script>

<template>
  <RouterLink
    v-slot="{ navigate }"
    :to="to"
    custom
  >
    <div
      class="flex flex-col md:flex-row md:items-center gap-1 text-left px-4 py-3 group cursor-pointer
      border border-gray-100 hover:border-blue-500 hover:bg-blue-100 hover:bg-opacity-30 bg-white rounded-md"
      @click="navigate"
    >
      <div class="flex justify-between md:justify-start items-center">
        <slot name="title">
          <span class="text-lg text-gray-900">{{ title }}</span>
        </slot>
        <DsIcon
          name="chevron-right"
          size="sm"
          fixed-width
          class="opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-gray-700 ml-2"
        />
        <slot />
      </div>
      <slot name="meta" />
    </div>
  </RouterLink>
</template>
