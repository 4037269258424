import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faCheckCircle,
  faCircle,
  faCopy,
  faFile as farFile,
  faFileAlt,
  faFilePdf,
  faHandshake,
  faQuestionCircle,
  faTimesCircle,
  faUser,
  faUserCircle,
  faXmarkCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAlignJustify,
  faArrowDown,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faBan,
  faBars,
  faCalculator,
  faCamera,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle as solidFaCircle,
  faCircleInfo,
  faComment,
  faDownload,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope as solidFaEnvelope,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileLines as solidFaFileLines,
  faFileContract,
  faFolderOpen,
  faHandshake as solidFaHandshake,
  faHome,
  faInfo,
  faInfoCircle,
  faKey,
  faMinus,
  faMobile,
  faPencil,
  faPlus,
  faShield,
  faSignOutAlt,
  faSync,
  faTablet,
  faTasks,
  faTimes,
  faListCheck as solidListCheck,
  faUpload,
  faUser as solidFaUser,
  faUserCircle as solidFaUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faAddressCard,
  faArrowRight as lightFaArrowRight,
  faBoxArchive,
  faComments,
  faCircleInfo as lightFaCircleInfo,
  faDownload as lightFaDownload,
  faEnvelopeOpen,
  faFilePdf as lightFaFilePdf,
  faFileContract as lightFaFileContract,
  faLightbulb,
  faList,
  faLocationDot,
  faMailbox,
  faPaperclip as lightFaPaperclip,
  faPencil as lightFaPencil,
  faRightFromBracket,
  faTrash as lightFaTrash,
  faUser as lightFaUser,
  faFile as lightFile,
  faChevronRight as lightChevronRight,
  faUsers,
  faFilter,
  faHandWave,
  faTimes as lightTimes,
  faMessagesQuestion as falMessagesQuestion,
  faEmptySet,
  faFaceViewfinder,
  faFingerprint,
  faPlay as falPlay,
  faBan as falBan,
} from '@fortawesome/pro-light-svg-icons';
import {
  faBadgePercent,
  faEnvelope,
  faEllipsis as regularFaEllipsis,
  faShield as regularFaShield,
  faKey as regularFaKey,
  faMessageExclamation,
  faPaperclip,
  faPhone,
  faTrash,
  faXmarkLarge,
  faLock,
  faArrowLeftLong as regularFaArrowLeftLong,
  faMoneyCheckDollar,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faDogLeashed,
  faFileLines,
  faHandshake as thinFaHandshake,
  faHorseSaddle,
  faListCheck,
  faUser as thinFaUser,
} from '@fortawesome/pro-thin-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faLock,
  faFaceViewfinder,
  faFingerprint,
  falMessagesQuestion,
  lightFile,
  solidListCheck,
  solidFaCircle,
  solidFaEnvelope,
  solidFaUser,
  solidFaUserCircle,
  faCheckCircle,
  faCircle,
  faEnvelope,
  faFileAlt,
  faFilePdf,
  faFileLines,
  solidFaFileLines,
  faHandshake,
  solidFaHandshake,
  thinFaHandshake,
  faPhone,
  faQuestionCircle,
  faTimesCircle,
  faUser,
  lightFaUser,
  thinFaUser,
  faUserCircle,
  faArrowDown,
  faTablet,
  faMobile,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faCalculator,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  lightChevronRight,
  faChevronUp,
  faCircle,
  faComment,
  faComments,
  faDownload,
  lightFaDownload,
  faExclamationCircle,
  faExternalLinkAlt,
  faFile,
  farFile,
  faHome,
  faInfo,
  faInfoCircle,
  faLightbulb,
  faMinus,
  faPaperclip,
  faPlus,
  faSignOutAlt,
  faSync,
  faTasks,
  faTimes,
  faUser,
  faUserCircle,
  faKey,
  faUpload,
  faFolderOpen,
  faBan,
  faCamera,
  faFileContract,
  faAlignJustify,
  faPencil,
  lightFaPencil,
  faEllipsis,
  regularFaEllipsis,
  regularFaKey,
  regularFaShield,
  regularFaArrowLeftLong,
  faEllipsisVertical,
  faXmarkCircle,
  faXmarkLarge,
  faCopy,
  faCircleInfo,
  lightFaArrowRight,
  faArrowLeftLong,
  lightFaCircleInfo,
  faShield,
  faEye,
  faEyeSlash,
  faLocationDot,
  faTrash,
  lightFaTrash,
  faUsers,
  faMessageExclamation,
  faBadgePercent,
  faBoxArchive,
  faMailbox,
  faDogLeashed,
  faHorseSaddle,
  faListCheck,
  faList,
  faRightFromBracket,
  lightFaFilePdf,
  lightFaFileContract,
  lightFaPaperclip,
  faSpinnerThird,
  faEnvelopeOpen,
  faAddressCard,
  faMoneyCheckDollar,
  faHandWave,
  faFilter,
  lightTimes,
  faEmptySet,
  faBell,
  falPlay,
  falBan,
);
