<script setup lang="ts">
import { updateProgress } from "@/application/plugins/liveUpdate";
import { DsIcon } from "@demvsystems/design-components";
import ActionSheet from "@/application/components/ActionSheet.vue";
</script>

<template>
  <Transition name="fade" appear>
    <div
      v-if="!!updateProgress"
      class="overlay h-dvh bg-gray-100 bg-opacity-30 backdrop-blur overflow-hidden"
    >
      <Transition name="slide-y" appear>
        <ActionSheet class="absolute bottom-0 left-0">
          <div class="pb-safe">
            <div class="py-10 mx-auto w-36 text-gray-700 flex align-items-center">
              <DsIcon
                class="mr-2"
                name="spinner-third"
                variant="duotone"
                spin
                size="lg"
              />
              Update {{ updateProgress }}%
            </div>
          </div>
        </ActionSheet>
      </Transition>
    </div>
  </Transition>
</template>
