import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 25 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M24.77 24.43c0-.22-.03-.43-.05-.65a9.75 9.75 0 0 0-4.86-7.39c.84-.37 1.53-.9 1.97-1.38a.75.75 0 0 0-.15-1.13c-.02-.01-2.05-1.39-2.05-6.43 0-4.13-3.03-7.4-6.9-7.44h-.31C8.57.05 5.48 3.39 5.5 7.45v.06c-.01 4.98-2.03 6.36-2.05 6.37-.18.1-.3.3-.34.53s.04.44.2.6a6.2 6.2 0 0 0 1.96 1.38 9.75 9.75 0 0 0-4.9 8.04c-.05.8-.07 1.61-.08 2.4a4.33 4.33 0 0 0 3.5 4.38 50.3 50.3 0 0 0 17.55 0 4.33 4.33 0 0 0 3.5-4.38c-.01-.79-.04-1.6-.08-2.4zM8.65 11.05l-.08-2.14c1-.62 1.75-1.52 2.15-2.08 1.2.73 3.16 1.69 5.84 2.23L16.5 11a4.02 4.02 0 0 1-3.93 3.97h-.05a3.96 3.96 0 0 1-3.87-3.92m1.37 4.7c.74.43 1.6.67 2.5.67h.06a5.1 5.1 0 0 0 2.53-.68 5 5 0 0 0 1.17.73l-3.67 3.9-3.74-3.9c.39-.17.77-.4 1.15-.71zm11.1 14.03a49 49 0 0 1-17.1 0 2.9 2.9 0 0 1-2.32-2.93 66 66 0 0 1 .11-2.9A8.46 8.46 0 0 1 7.41 17l4.7 4.9a.7.7 0 0 0 1 .01l4.63-4.9a8.46 8.46 0 0 1 5.64 7.5c.04.78.06 1.57.07 2.34a2.9 2.9 0 0 1-2.34 2.93z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }