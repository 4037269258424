import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M24 17.14v-2.28q0-.47-.34-.8t-.8-.35h-4.57V9.14q0-.46-.34-.8t-.8-.34h-2.3q-.46 0-.8.34t-.34.8v4.57H9.14q-.46 0-.8.34t-.34.8v2.3q0 .46.34.8t.8.34h4.57v4.57q0 .46.34.8t.8.34h2.3q.46 0 .8-.34t.34-.8v-4.57h4.57q.46 0 .8-.34t.34-.8zM29.71 16q0 3.73-1.84 6.88t-4.99 5T16 29.7t-6.88-1.84-5-4.99T2.3 16t1.83-6.88 5-5T16 2.3t6.88 1.83 5 5T29.7 16z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }