<script setup lang="ts">

import { DsSkeleton } from '@demvsystems/design-components';
import CardGrid from '@/contracts/components/CardGrid.vue';
</script>

<template>
  <div class="flex flex-col gap-12">
    <div class="flex flex-col md:flex-row justify-between">
      <DsSkeleton gradient class="w-full md:w-72 h-9" />
      <DsSkeleton gradient class="md:hidden w-2/3 h-6 mt-5" />
      <DsSkeleton gradient class="hidden md:block w-36 h-9" />
    </div>
    <CardGrid>
      <DsSkeleton gradient class="h-32" />
      <DsSkeleton gradient class="h-32" />
    </CardGrid>
  </div>
</template>
