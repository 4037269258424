<template>
  <ShallowCard
    :class="{'cursor-pointer': !disabled}"
    :no-hover="disabled || noHover"
  >
    <DsAccordion
      :trigger-full-width="!disabled"
      :model-value="open"
    >
      <template
        v-if="!disabled"
        #trigger="{ open: isOpen }"
      >
        <div class="flex flex-row items-center justify-between">
          <h5
            class="text-gray-900 mb-0"
            v-text="title"
          />
          <DsIcon
            name="arrow-right"
            variant="light"
            size="lg"
            class="transition-all duration-300 text-gray-700"
            :rotation="isOpen ? '90' : undefined"
          />
        </div>
      </template>
      <template #header>
        <div
          data-testid="accordion-header"
          class="flex flex-row flex-grow items-center justify-between"
        >
          <span class="text-lg text-gray-900">{{ title }}</span>
          <DsIcon
            name="arrow-right"
            class="opacity-75"
            size="lg"
          />
        </div>
      </template>
      <template #default>
        <div
          class="overflow-auto"
          :class="`max-h-[${maxHeight}]`"
        >
          <slot />
        </div>
      </template>
    </DsAccordion>
  </ShallowCard>
</template>

<script setup lang="ts">
import { DsAccordion, DsIcon } from '@demvsystems/design-components';

import ShallowCard from '@/contracts/components/ShallowCard.vue';

interface Props {
  title: string,
  maxHeight?: string,
  disabled?: boolean,
  open?: boolean,
  noHover?: boolean,
}

withDefaults(defineProps<Props>(), {
  maxHeight: '200px',
});
</script>
