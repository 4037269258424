<script setup lang="ts">
import { DsButton } from "@demvsystems/design-components";

</script>

<template>
  <div class="flex flex-grow bg-light">
    <div class="container flex flex-col flex-grow items-center justify-center">
      <span class="text-xl md:text-2xl lg:text-3xl">
        Oops! Diese Seite gibt es nicht.
      </span>
      <div class="py-5 md:py-7 lg:py-10">
        <RouterLink
          v-slot="{ navigate }"
          to="/"
          custom
        >
          <DsButton
            :handler="navigate"
            size="lg"
          >
            Zur Startseite
          </DsButton>
        </RouterLink>
      </div>
    </div>
  </div>
</template>
