<template>
  <div>
    <div
      v-for="({ text, title, svgIcon }, i) in items"
      :key="title"
      class="md:flex border-b p-5 md:p-7 flex-col md:flex-row"
    >
      <div
        class="self-center text-center"
        :class="[i % 2 == 0 ? 'order-md-2 md:ml-7' : 'md:mr-7']"
      >
        <SvgVue
          :icon="svgIcon"
          style="width: 128px"
          class="inline-block"
        />
      </div>
      <div class="self-center w-full mt-5 md:mt-0">
        <!-- eslint-disable vue/no-v-html -->
        <h5
          class="mb-2.5 md:mb-1.5"
          v-html="title"
        />
        <!-- eslint-enable vue/no-v-html -->
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgVue from "@/application/components/SvgVue";

type FlippingListItem = {
  title: string,
  text: string,
  svgIcon: string,
};

interface Props {
  items: FlippingListItem[]
}

defineProps<Props>();
</script>
