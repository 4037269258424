<script setup lang="ts">

import type { Coinsured } from "@/api/contracts/types";

interface Props {
  coinsuredPersons: Coinsured[];
}

defineProps<Props>();
</script>

<template>
  <div class="flex flex-col gap-1 items-end">
    <div
      v-for="person in coinsuredPersons"
      :key="person.id"
      class="flex flex-col items-end"
    >
      <span class="font-medium text-gray-900 text-right">{{ person.firstName }} {{ person.lastName }}</span>
      <span class="text-muted text-xs">{{ person.relation }}</span>
    </div>
  </div>
</template>
