<template>
  <ButtonWithMenu>
    <button
      v-for="item in items"
      :key="item"
      class="flex flex-row items-center text-sm sm:text-base text-gray-700 gap-2.5 whitespace-nowrap hover:bg-gray-100 -mx-4 -my-2 px-4 py-1.5"
      @click="$emit('select')"
    >
      <DsIcon
        name="envelope-open"
        variant="light"
      />
      {{ item }}
    </button>
  </ButtonWithMenu>
</template>
<script setup lang="ts">
import { DsIcon } from '@demvsystems/design-components';
import ButtonWithMenu from '@/application/components/ButtonWithMenu.vue';

interface Props {
  items: string[];
}

defineProps<Props>();

defineEmits(['select'])
</script>
