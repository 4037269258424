import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 288 288"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M104 237v-80l-52 6.1v56.8s-1.1 17.4 12.5 17.1zm66-133v133h-52V110zm66-54-52 6.1V237h37.8s14.4 1.7 14.2-15.5z"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }