<template>
  <DsModal
    :show="show"
    hide-buttons
    title="Neuen Vertrag anlegen"
    @cancel="$emit('close')"
  >
    <ModalWrapper>
      <CreateContract
        style="max-height: calc(100dvh - 7rem - env(safe-area-inset-top) - env(safe-area-inset-bottom))"
        @close="$emit('close')"
      />
    </ModalWrapper>
  </DsModal>
</template>

<script setup lang="ts">
import { DsModal } from '@demvsystems/design-components';

import CreateContract from '@/contracts/components/CreateContract.vue';
import ModalWrapper from "@/application/components/ModalWrapper.vue";

interface Props {
  show: boolean;
}

defineProps<Props>();
defineEmits(['close']);
</script>
