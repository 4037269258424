<template>
  <section class="section section--background-black py-14 print:hidden">
    <div class="container">
      <div class="text-center mb-7">
        <h2>Zu welchem Thema wünschen Sie weitere Informationen?</h2>
      </div>
      <div class="flex flex-col md:flex-row gap-5 justify-center text-center mb-5">
        <div class="flex flex-col md:flex-row md:justify-center gap-4 md:gap-8 w-full lg:mx-44">
          <RouterLink
            :to="{ name: MeinBedarf.BERUFSUNFAEHIGKEIT}"
            class="w-full"
            :class="[currentRoute === MeinBedarf.BERUFSUNFAEHIGKEIT ? 'btn-light-lg' : 'btn-outline-light-lg']"
          >
            Berufsunfähigkeitsversicherung
          </RouterLink>
          <RouterLink
            :to="{ name: MeinBedarf.KRANKENTAGEGELD}"
            class="w-full"
            :class="[currentRoute === MeinBedarf.KRANKENTAGEGELD ? 'btn-light-lg' : 'btn-outline-light-lg']"
          >
            Krankentagegeld
          </RouterLink>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { MeinBedarf } from '@/application/router/types/types';

defineProps<{ currentRoute: MeinBedarf }>();
</script>
