<template>
  <!-- container class still bootstrap, rest tailwind -->
  <div class="container">
    <div class="flex flex-col items-center text-center gap-2">
      <h2>
        {{ headline }}
      </h2>
      <p
        v-if="subline"
        class="w-full lg:w-3/4"
      >
        {{ subline }}
      </p>
      <h4 class="my-4 lg:my-10">
        {{ secondHeadline }}
      </h4>
    </div>

    <div class="flex flex-col gap-10 md:gap-16">
      <div
        v-for="({icon, title, description}, index) in data"
        :key="icon"
        class="flex flex-col items-center md:justify-center md:gap-32"
        :class="[index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse']"
      >
        <SvgVue
          v-if="!isImage"
          :icon="icon"
          class="w-52 max-h-36"
        />
        <img
          v-else
          :src="icon"
          :alt="title"
          class="max-h-36"
        >
        <List
          :title="title"
          class="w-full md:w-3/5"
        >
          {{ description }}
        </List>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import List from '@/application/components/List.vue';

export type DamageExampleData = {
  icon: string,
  title: string,
  description: string,
};

interface Props {
  data: DamageExampleData[];
  headline: string,
  subline?: string,
  secondHeadline: string,
  isImage?: boolean
}

defineProps<Props>();

</script>
