<script setup lang="ts">
interface Props {
  highlight?: boolean,
  noHover?: boolean
}

defineProps<Props>();
</script>

<template>
  <div
    class="h-fit flex flex-col justify-between w-full bg-white p-3 border border-gray-100 rounded-lg hyphens-auto shadow transition-shadow"
    :class="[{'hover:shadow-lg hover:shadow-blue-100': !noHover && highlight, 'hover:shadow-lg': !noHover }]"
  >
    <slot />
  </div>
</template>
