<script setup lang="ts">

interface Props {
  hasDocuments: boolean;
  documentsCount: number;
  documentsWithContractsCount: number;
}

defineProps<Props>();
</script>

<template>
  <div class="flex gap-3">
    <span v-if="!hasDocuments" class="text-xs md:text-sm px-4 text-gray-500">Es liegen zur Zeit keine Dokumente von Ihnen vor.</span>
    <span v-if="hasDocuments" class="text-xs md:text-sm text-gray-800">
      <span class="pr-2">Dokumente</span>
      <span
        class="font-medium inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs md:text-sm font-medium text-green-800 ring-1 ring-inset ring-green-500/30"
      >
        {{ documentsCount }}
      </span>
    </span>
    <span v-if="hasDocuments" class="text-xs md:text-sm text-gray-800">
      <span class="px-2">Zugeordnete Dokumente</span>
      <span
        class="font-medium inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs md:text-sm font-medium text-green-800 ring-1 ring-inset ring-green-500/30"
      >
        {{ documentsWithContractsCount }}
      </span>
    </span>
  </div>
</template>
