<template>
  <div
    v-if="!inlineTitle && item?.title"
    class="mt-5"
  >
    <strong>{{ item.title }}</strong>
  </div>

  <div
    class="flex items-start mt-1.5"
    :class="[small ? 'mb-1.5' : 'mb-2.5']"
  >
    <DsIcon
      :name="item.icon ?? 'arrow-right'"
      :variant="item.iconVariant ?? 'solid'"
      class="p-1 rounded-full text-blue-700 bg-blue-100 shrink-0 my-0.5 mr-2"
      :class="small ? 'w-2.5 h-2.5' : 'w-3 h-3'"
    />
    <div class="media-body">
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="inlineTitle && item?.title"
        class="font-bold"
        v-html="item.title"
      />
      <div v-if="item.text" v-html="item.text" />
      <component :is="item.component" v-if="item.component" />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </div>
</template>

<script setup lang="ts">

import type { BulletPointItem } from './types';
import { DsIcon } from '@demvsystems/design-components';

interface Props {
  inlineTitle?: boolean,
  title?: string
  small?: boolean,
  item: BulletPointItem
}

defineProps<Props>();
</script>
