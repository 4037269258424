import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M50 .4C22.7.4.5 22.5.5 49.8S22.7 99.2 50 99.2s49.4-22.1 49.4-49.4S77.2.4 50 .4M67.9 22a9.7 9.7 0 0 1 0 19.4c-5.3 0-9.7-4.4-9.7-9.7 0-5.4 4.4-9.7 9.7-9.7m-6.8 33.4c0 3.4-2.7 6.1-6.1 6.1s-6.1-2.7-6.1-6.1 2.7-6.1 6.1-6.1c3.3.1 6.1 2.8 6.1 6.1m-21-37.2a11.4 11.4 0 1 1 .01 22.79 11.4 11.4 0 0 1-.01-22.79M18.7 69.8c0-14.3 9.6-25.8 21.4-25.8 4.7 0 9.1 1.9 12.6 5-2.7.9-4.7 3.5-4.7 6.6 0 3.4 2.4 6.2 5.5 6.8l-.3 1.7c-2.2.7-4.2 2.9-5.6 6-10.7.1-16.3 0-28.9-.3m27.4 11.3c0-8.9 3.7-16.2 8.3-16.2s8.3 7.2 8.3 16.2c-8.3.3-10 .2-16.6 0m15.6-10.2c-1.6-3.7-4-6.2-6.7-6.5l.3-1.8c3.7-.2 6.6-3.2 6.6-7 0-2.1-.9-3.9-2.3-5.2 2.3-3.6 5.2-5.7 8.3-5.7 7.3 0 13.3 11.6 13.3 25.8-9.8.5-13.8.6-19.5.4"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }