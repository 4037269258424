import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M72.8 33.9H54.9v11.3h17.9zm3.8 13.5H54.3v1.5h22.3zm2 14.9H64.8v2.8h13.8zm-2-11H54.3v1.5h22.3zm1.1-26.4H53v3h24.7z"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M50.2.8C23 .8.9 22.9.9 50.1s22 49.3 49.3 49.3 49.3-22.1 49.3-49.3S77.4.8 50.2.8M39.4 28.2c6.1 0 11 4.9 11 11s-4.9 11-11 11-11-4.9-11-11 4.9-11 11-11M18.8 77.3c0-13.7 9.2-24.9 20.6-24.9S60 63.5 60 77.3c-20.7.5-24.9.4-41.2 0m62.8-5.6h-20c-2.2-11.1-10.2-19.5-19.9-20.1v-1c5.9-.5 10.5-5.5 10.5-11.6s-4.6-11.1-10.5-11.6v-5.6h39.8v49.9z"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }