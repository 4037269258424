<template>
  <div>
    <div
      class="inline-block mx-auto relative px-4 py-0.5 rounded-full transition duration-200 ease-in-out"
      :class="{'bg-blue-100': isActive}"
    >
      <FontAwesomeIcon
        :icon="isActive ? activeIcon : icon"
        fixed-width
        size="lg"
        :class="isActive ? 'text-blue-500' : 'text-gray-700'"
      />
      <MenuHint :number="unreadHints" />
    </div>
    <div
      class="font-medium mt-1"
      :class="isActive ? 'text-blue-500' : 'text-gray-500'"
      style="font-size: 0.75rem;"
    >
      {{ title }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import MenuHint from '@/application/menu/components/MenuHint.vue';

type Props = {
  isActive?: boolean,
  title: string,
  icon: string | string[],
  activeIcon?: string | string[],
  unreadHints: number | null,
};

withDefaults(
  defineProps<Props>(),
  { isActive: false, activeIcon: '' }
);
</script>

<style scoped>
.unread-hint {
  top: -2px;
  transform: translateX(10px);
}
</style>
