import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.89"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "49.44",
      cy: "49.44",
      r: "49.44",
      fill: "#4181D2"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M40.07 55.72c.15.04.27.06.24.27a30 30 0 0 0-.27 3.88c-.02 1.06.07 2.11.29 3.14a8.03 8.03 0 0 0 3.68 5.41c1.11.7 2.33 1.15 3.59 1.47 2.19.56 4.44.75 6.69.86q1.86.09 3.72.09c2.45.01 4.9.04 7.35-.02 1.43-.03 2.87-.06 4.3-.16 1.3-.09 2.6-.23 3.89-.46 1.52-.28 2.99-.68 4.37-1.4a7.88 7.88 0 0 0 3.66-3.75 12.6 12.6 0 0 0 .73-7.09c-.4-2.1-1.26-4.02-2.45-5.79a22.6 22.6 0 0 0-5.24-5.45 31.7 31.7 0 0 0-11.24-5.41 24.6 24.6 0 0 0-8.01-.88c-.36.03-.36.02-.36-.35v-4.43c0-.3.03-.32.32-.3l2.25.14 3.94.2 3.89.2 3.92.2 3.94.2 3.92.2 3.51.16c.42.02.84.04 1.26.08a3.85 3.85 0 0 0 2.83-.66c1.43-1.1 1.48-2.99.09-4.13a3.47 3.47 0 0 0-2.62-.76c-1.44.13-2.88.18-4.32.27l-4.32.25-3.48.2-3.43.2-4.32.25-3.97.21-1.16.08-2 .12c-.21.01-.26-.08-.26-.27a2.98 2.98 0 0 0-2.98-3.09c-1.29.01-2.13.62-2.68 1.72-.22.43-.26.9-.26 1.38 0 .2-.06.28-.26.26l-.76-.05-2.05-.11-4.07-.25-1.97-.11-4.17-.25-1.87-.11-4.14-.25-1.89-.11-4.09-.25c-.67-.04-1.34-.04-1.99-.12a2.84 2.84 0 0 0-3.22 3.19 2.8 2.8 0 0 0 2.71 2.38c1.11 0 2.21-.09 3.31-.15 1.4-.07 2.8-.15 4.19-.2 1.04-.04 2.07-.09 3.11-.15 1.41-.08 2.83-.14 4.25-.21l4.12-.2c1.26-.06 2.51-.1 3.77-.17l1.26-.06 3.49-.19c.21-.01.28.05.28.27v5.88c0 .18-.06.27-.22.35-1.92.95-3.51 2.3-4.8 4a.63.63 0 0 1-.59.28l-14.77-.01H13.78c-.77.02-1.29.51-1.39 1.28-.1.75.26 1.34.99 1.55.71.21 1.43.4 2.15.58l12.13 3.01 8.57 2.11c1.25.39 2.55.71 3.84 1.03m18.58-3.5c.11-1.18.26-2.35.65-3.47.2-.58.45-1.13.89-1.57.38-.39.84-.59 1.4-.59 1.57 0 3.08.37 4.55.89 2.16.77 4.15 1.86 5.94 3.31a12.9 12.9 0 0 1 3.37 3.9c.51.97.84 1.99.86 3.09 0 .29.07.57.03.86-.11.81-.59 1.34-1.28 1.72-.82.45-1.71.65-2.62.81-1.31.23-2.63.31-3.95.37-.73.03-1.45.02-2.18.04-1.33.03-2.65 0-3.94-.36A5.4 5.4 0 0 1 60 59.96a4.6 4.6 0 0 1-1.35-2.66 6 6 0 0 1-.11-1.11 38 38 0 0 1 .11-3.97m-14.25.67h4.5v-4.5h2.87v4.5h4.5v2.9h-4.5v4.48H48.9v-4.48h-4.5z"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M14.12 45.03c-.38 0-1.11-.79-1.64-1.76l-.64-1.18c-.52-.97-.05-1.76 1.05-1.76h1.35c1.1 0 1.74.86 1.42 1.91l-.27.87c-.32 1.06-.9 1.92-1.27 1.92m-.59 5.12c-.38 0-1.11.79-1.64 1.76l-.64 1.18c-.52.97-.05 1.76 1.05 1.76h1.35c1.1 0 1.74-.86 1.42-1.91l-.27-.87c-.32-1.06-.89-1.92-1.27-1.92"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }