<template>
  <SpartenWrapper
    image="images/bedarfsinfo/hausrat/hausrat-hintergrund"
    text="Feuer, Sturm, Einbruch oder das Austreten von
          Leitungswasser – jedes Jahr entstehen in privaten
          Haushalten mehr als eine Million dieser
          Schadenereignisse. In welchem Umfang eine
          Hausratversicherung Sie finanziell schützt, erfahren
          Sie jetzt."
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie gut ist mein Hausrat versichert?"
    versorgungen-description="Hausratversicherung"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Was zählt alles zu meinem Hausrat?</h2>
            </div>

            <div class="text-center pb-7">
              <p>
                Alle nicht fest mit dem Gebäude verbundenen Gegenstände zählen zum Hausrat.
              </p>
            </div>
          </div>
        </div>

        <div class="row mb-7">
          <div class="col-12 col-md-6 col-lg-8">
            <SvgVue
              icon="bedarfsinfo/hausrat/haus-auf-dem-kopf.svg"
              class="block mx-auto img-fluid my-5"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <BulletPointList
              title="Folgende Gegenstände sind in Ihrem Haushalt versichert:"
              :items="[
                { title: 'Einrichtungsgegenstände', text: 'Möbel, Teppiche und Vorhänge' },
                { title: 'Gebrauchsgegenstände', text: 'Haushaltsgeräte, Bücher, Kleidung und Computer' },
                { title: 'Verbrauchsgegenstände', text: 'Nahrungs- und Genussmittel' },
                { title: 'Bargeld und Wertgegenstände', text: 'Bilder oder Schmuck' },
                { title: 'Sonstige Gegenstände', text: 'Kfz-Zubehör, Sportgeräte usw.' },
              ]"
              inline-title
            />
          </div>
        </div>

        <div class="row mb-14">
          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="[{
                title: 'Für wen zählt der Versicherungsschutz?',
                text: 'Der Versicherungsschutz besteht immer für den Hausrat aller Familienmitglieder.' +
                  '<br> Mieter oder Untermieter benötigen hingegen eine eigene Hausratversicherung.',
              }]"
            />
          </div>

          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="[{
                title: 'Kann ich auch auf Reisen meinen Hausrat versichern?',
                text: 'Über die Außenversicherung ist der mitgeführte Hausrat ' +
                  'in bestimmten Grenzen auch auf Reisen versichert.',
              }]"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Wann habe ich Anspruch auf Leistungen aus der Hausratversicherung?</h2>
            </div>

            <div class="text-center pb-7">
              <p>
                Wird der Hausrat in Folge einer der folgenden Ereignisse beschädigt, entsteht Anspruch auf
                Erstattung
                der finanziellen Schäden:
              </p>
            </div>
          </div>
        </div>

        <div class="row mb-7">
          <div class="col-12 col-md-6 col-lg-4">
            <BulletPointList
              title="Die Hausratver&shy;sicherung erstattet Schäden infolge von..."
              :items="[
                { title: 'Feuer', text: 'z. B. durch Defekte in elektrischen Anlagen' },
                { title: 'Leitungswasser', text: ' z. B. durch alte/verdreckte Rohre oder eine defekte Waschmaschine' },
                {
                  title: 'Sturm und Hagel',
                  text: 'z. B. durch zerbrochene Fensterscheiben und daraus resultierende Schäden durch Wassereintritt',
                },
                {
                  title: 'Einbruchdiebstahl und Raub',
                  text: 'z. B. durch gewaltsames Aufbrechen der Türen oder Fenster',
                },
                { title: 'Vandalismus', text: 'z. B. mutwillige Zerstörung des Eigentums' },
              ]"
              inline-title
            />
          </div>

          <div class="col-12 col-md-6 col-lg-8">
            <SvgVue
              icon="bedarfsinfo/hausrat/haus-schaeden.svg"
              class="block mx-auto img-fluid my-5"
              style=""
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light">
      <div class="container">
        <div class="row pb-7">
          <div class="col">
            <div class="text-center">
              <h3>Um welche Leistungen kann ich den Versicherungsschutz individuell erweitern?</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            v-for="item in [ { icon: 'ueberspannungsschaeden', title: 'Überspannungsschäden' },
                             { icon: 'elementarschaeden', title: 'Elementarschäden' },
                             { icon: 'fahrraddiebstahl', title: '24-Stunden Schutz bei Fahrraddiebstahl an jedem Ort' },
                             { icon: 'glasbruch', title: 'Glasbruch, unabhängig von der Schadenursache' }]"
            :key="item.title"
            class="flex flex-col items-center col-12 col-md-6 col-lg-3 mb-7"
          >
            <SvgVue
              class="w-1/2 mb-5"
              style="max-width: 120px;"
              :icon="`bedarfsinfo/hausrat/${item.icon}.svg`"
            />
            <div class="text-center">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Wann habe ich keinen Versicherungsschutz?</h2>
            </div>
          </div>
        </div>

        <div class="row mb-7">
          <div class="col-12 col-md-6 col-lg-8">
            <SvgVue
              icon="bedarfsinfo/hausrat/haus-leistungausschluesse.svg"
              class="block mx-auto img-fluid my-5"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <BulletPointList
              title="Bedeutende Leistungs&shy;ausschlüsse"
              :items="[
                {
                  title: 'Einfacher Diebstahl und Trickdiebstahl',
                  text: 'z. B. wenn der Täter Ihr Eigentum entwendet, ohne dafür ein Hindernis überwinden zu müssen'
                },
                { title: 'Vorsatz', text: ' durch einen selbst verursachten Schaden' },
                {
                  title: 'Sengschäden',
                  text: 'Häufig verursacht durch glimmende Zigaretten oder Glut' },
                {
                  title: 'Eindringen von Feuchtigkeit', text: 'z. B. aufgrund großer Schneemassen' },
                { title: 'Höhere Gewalt',
                  text: 'bei Schäden durch Kernenergie, Kriegsereignisse, innere Unruhen oder Erdbeben' },
              ]"
              inline-title
            />
          </div>
        </div>

        <div class="row mb-14">
          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="[{
                title: 'Hinweis',
                text: 'Teilweise bieten Tarife auch für diese Ereignisse eingeschränkten ' +
                  'Versicherungsschutz.<br> <b>Bitte teilen Sie uns mit, falls Sie den Einschluss wünschen.</b>',
              }]"
            />
          </div>

          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="[{
                title: 'Hinweis',
                text: 'Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend.'
              }]"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Welche Zahlungen erhalte ich im Schadenfall?</h2>
            </div>

            <BulletPointList
              :items="[
                {text: 'Üblicherweise werden die Kosten der zerstörten oder abhandengekommenen Sachen erstattet, ' +
                  'die notwendig sind, um diese in gleicher Art und Güte in neuwertigem Zustand wiederzubeschaffen.'},
                {text:'Bei beschädigten Sachen werden die Reparaturkosten erstattet und eine Wertminderung gewährt.'},
                {text:'Zusätzlich werden mit dem Schaden entstehende indirekte Kosten erstattet, ' +
                  'wie beispielsweise Aufräumkosten oder Hotelkosten, falls die Immobilie nicht bewohnbar ist.'},
              ]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light">
      <div class="container">
        <div class="row pb-7">
          <div class="col">
            <div class="text-center">
              <h3>Was kann ich im Vorfeld unternehmen, damit der Schaden unkompliziert erstattet wird?</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 mb-7">
            <p>
              Sachbearbeiter der Versicherungsgesellschaften prüfen die eingereichten Schäden unter anderem
              auf Plausibilität. So wird die Versicherungsgemeinschaft vor Betrügern geschützt und die
              Beiträge bleiben für alle möglichst niedrig.
            </p>
            <p>
              Um im Schadenfall schnelle finanzielle Hilfe zu erhalten, ist es sinnvoll, wenn Sie den Wert
              des Hausrats nachweisen können. Filmen oder fotografieren Sie Ihren Hausrat und bewahren Sie
              Rechnungen von größeren Anschaffungen auf.
            </p>
            <p>
              Am besten lagern Sie diese Dokumente extern, so dass Sie jederzeit darauf Zugriff haben, falls
              es zu einem Schadenfall kommt.
            </p>
          </div>

          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12 col-md-6 mb-7">
                <SvgVue
                  icon="bedarfsinfo/hausrat/fotos-filme.svg"
                  class="block mx-auto img-fluid mb-5"
                  style="max-width: 120px;"
                />
                <div class="text-center">
                  Fotos / Filme
                </div>
              </div>
              <div class="col-12 col-md-6 mb-7">
                <SvgVue
                  icon="bedarfsinfo/hausrat/zeichnungen.svg"
                  class="block mx-auto img-fluid mb-5"
                  style="max-width: 120px;"
                />
                <div class="text-center">
                  Zeichnungen
                </div>
              </div>
              <div class="col-12 col-md-6 mb-7">
                <SvgVue
                  icon="bedarfsinfo/hausrat/prospektabbildungen.svg"
                  class="block mx-auto img-fluid mb-5"
                  style="max-width: 120px;"
                />
                <div class="text-center">
                  Prospektabbildungen
                </div>
              </div>
              <div class="col-12 col-md-6 mb-7">
                <SvgVue
                  icon="bedarfsinfo/hausrat/quittungen.svg"
                  class="block mx-auto img-fluid mb-5"
                  style="max-width: 120px;"
                />
                <div class="text-center">
                  Quittungen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Schadenbeispiele</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <BulletPointList
              description="Infolge eines Rohrbruchs wird durch austretendes Leitungswasser
              der Teppich zerstört und die Schränke beschädigt."
              :items="[
                {text: 'Die Versicherung erstattet die Kosten für einen gleichartigen Teppich in neuwertigem Zustand.'},
                {text: 'Die Versicherung übernimmt die durch einen Tischler entstandenen Reparaturkosten ' +
                  'der Schränke und zahlt zusätzlich eine Wertminderung an den Kunden.'}
              ]"
            />
          </div>
          <div class="col-12 col-lg-6">
            <BulletPointList
              description="Diebe brechen in das Haus ein und entwenden Fernseher, Schmuck und Bargeld."
              :items="[
                { text: 'Im Rahmen der versicherten Höchstgrenzen erstattet die Versicherung den Gegenwert der ' +
                  'entwendeten Sachen.' },
                { text: 'Durch den Einbruch ist das Schloss der Haustür so stark beschädigt, dass die Tür nicht ' +
                  'abgeschlossen werden kann. Die Versicherung übernimmt die Kosten zur Bewachung der Immobilie und ' +
                  'die Hotelkosten, bis die Haustür wieder funktionsfähig ist.' },
              ]"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();
</script>
