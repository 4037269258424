import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1416 1176"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "718.4",
      cy: "587.7",
      r: "567.1",
      fill: "#4181D2"
    }, null, -1 /* HOISTED */),
    _createElementVNode("circle", {
      cx: "603",
      cy: "244.4",
      r: "98",
      style: {"fill":"#fff"},
      transform: "rotate(-45 603.04 244.45)"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "M1012.8 368c-.6-9-8.3-16-17.3-15.6l-76.4 3.2c-9.1.4-16.3 8.1-15.9 17.2l10.9 293.7c.3 9.3-6.9 17-16.2 17.3l-25.6.7a49.1 49.1 0 0 0 13.4-17.5c12.5-18.6 15.6-43.1 5.9-65L787.8 368.5c-15-33.8-55-49.2-88.8-34.1L666.2 349c-33.8 15-49.2 55-34.1 88.8L704.2 600H558.9a48.02 48.02 0 0 0-50.1 19.8c-4.3 5.9-7.2 12.8-8.4 20.3L415.9 914c-7.7 25 6.4 51.8 31.5 59.6 25 7.7 51.8-6.4 59.6-31.5l76.2-246.8h49.2a16.62 16.62 0 0 0-9 18.7c22.2 93.6 66.8 118.1 88.1 124.6 7 2.1 11.8 8.6 11.8 15.9V936c0 14.1 11.5 25.6 25.7 25.5l117.4-.7c13.2-.1 23.1-12.1 20.7-25.1-5.6-30.7 5.3-71.7 9.7-86.3 1-3.2 2.8-6 5.4-8.1 28.7-22.9 35-90 36.4-114.9.3-5.9 3.7-11.1 8.9-13.9l52.7-27.4c3.2-1.7 5.8-4.3 7.3-7.6 20.3-42.7 9-249.6 5.3-309.5",
      style: {"fill":"#fff"}
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }