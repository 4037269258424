<script lang="ts" setup>
import { isProductionEnv } from "@/application/utils/envInfo";
import { Finanzmanager } from "@/application/router/types/types";
</script>

<template>
  <div class="bg-gray-50 max-lg:text-center">
    <div class="scontainer space-x-4 md:space-x-10 text-xs md:text-sm py-3">
      <a v-if="isProductionEnv" href="#" onclick="CCM.openWidget();return false;" class="link">Cookies</a>
      <RouterLink :to="{ name: Finanzmanager.DATENSCHUTZ }" class="link">Datenschutz</RouterLink>
      <RouterLink :to="{ name: Finanzmanager.IMPRESSUM }" class="link">Impressum</RouterLink>
    </div>
  </div>
</template>

<style scoped>
.link {
  @apply text-gray-500 hover:text-dark transition-colors;
}
</style>
