import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 288 288"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M226.3 0H61.7C27.6 0 0 27.6 0 61.7v164.6C0 260.4 27.6 288 61.7 288h164.6c34.1 0 61.7-27.6 61.7-61.7V61.7C288 27.6 260.4 0 226.3 0M104 237H64.5c-13.6.3-12.5-17.1-12.5-17.1v-56.8l52-6.1zm66 0h-52V110l52-6zm66-15.5c.2 17.2-14.2 15.5-14.2 15.5H184V56.1l52-6.1z"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }