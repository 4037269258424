<template>
  <SpartenWrapper
    image="images/bedarfsinfo/hausrat/hausrat-hintergrund"
    text="Feuer, Sturm, Einbruch oder das Austreten von Leitungswasser -
          jedes Jahr entstehen in Unternehmen zahlreiche dieser
          Schadenereignisse. In welchem Umfang eine
          Geschäftsinhaltsversicherung Sie finanziell schützt,
          erfahren Sie jetzt."
    :versorgungen="data.bedarfsinfo.versorgungen"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie gut ist unser Geschäftsinhalt versichert?"
    versorgungen-description="Geschäftsinhaltsversicherung"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Was zählt alles zu meinem Geschäftsinhalt?</h2>
            </div>

            <div class="text-center pb-7">
              <p>
                Alle nicht fest mit dem Gebäude verbundenen Gegenstände zählen zum Geschäftsinhalt.
              </p>
            </div>
          </div>
        </div>

        <div class="row mb-7">
          <div class="col-12 col-md-6 col-lg-8">
            <SvgVue
              icon="bedarfsinfo/hausrat/haus-auf-dem-kopf.svg"
              class="block mx-auto img-fluid mb-5"
              style=""
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4 flex flex-col justify-around">
            <BulletPointList
              title="Folgende Gegenstände sind in Ihrem Betrieb versichert:"
              :items="[
                { text: 'Kaufmännische und technische Betriebseinrichtung' },
                { text: 'Waren' },
                { text: 'Lagerbestände' },
                { text: 'Elektronisches Equipment' },
                { text: 'Arbeitsmaschinen' },
                { text: 'Bargeld und Wertpapiere' },
                { text: 'Werbeschilder' },
              ]"
            />
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Wo besteht Versicherungsschutz?</h3>
            </div>

            <div class="text-center pb-7">
              <p>
                Der Versicherungsschutz besteht immer in den Betriebs-, Geschäfts- und Lagerräumen,
                die im Versicherungsschein angegeben sind.
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-7">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Wann habe ich Anspruch auf Leistungen aus der Geschäftsinhaltsversicherung?</h3>
            </div>

            <div class="text-center pb-7">
              <p>
                Wird der Geschäftsinhalt in Folge einer der folgenden Ereignisse beschädigt,
                entsteht Anspruch auf Erstattung der finanziellen Schäden:
              </p>
            </div>
          </div>
        </div>

        <div class="row md:items-center lg:items-stretch">
          <div class="col-12 col-md-6 col-lg-4 flex flex-col justify-around">
            <BulletPointList
              :items="[
                { 'title': 'Feuer', 'text': 'z. B. durch Defekte in elektrischen Anlagen' },
                { 'title': 'Leitungswasser', 'text': ' z. B. durch alte/verdreckte Rohre' },
                {
                  'title': 'Sturm und Hagel',
                  'text': 'z. B. durch zerbrochene Fensterscheiben und daraus resultierende ' +
                    'Schäden durch Wassereintritt'
                },
                {
                  'title': 'Einbruchdiebstahl und Raub',
                  'text': 'z. B. durch gewaltsames Eindringen in die Räumlichkeiten des Betriebes'
                },
                {
                  'title': 'Vandalismus',
                  'text': 'z. B. mutwillige Zerstörung des Eigentums'
                },
              ]"
              inline-title
            />
          </div>

          <div class="col-12 col-md-6 col-lg-8">
            <SvgVue
              icon="bedarfsinfo/hausrat/haus-schaeden.svg"
              class="block mx-auto img-fluid my-5"
              style=""
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light">
      <div class="container">
        <div class="row pb-7">
          <div class="col">
            <div class="text-center">
              <h3>Um welche Leistungen kann ich den Versicherungsschutz individuell erweitern?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-around">
          <div
            v-for="item in [
              {
                'icon': 'elementarschaeden',
                'title': 'Elementarschäden',
                'text': 'z. B. Hochwasser und Überschwemmung, aber auch Erdbeben, Erdsenkung und (Schnee-)lawine'
              },
              {
                'icon': 'betriebsschliessung',
                'title': 'Betriebsunterbrechungsversicherung/<br>Betriebsschließungsversicherung',
                'text': 'z. B. wenn ihr Betrieb in Folge eines oben genannten Ereignisses temporär nicht ' +
                  'weitergeführt werden kann, erhalten Sie die Versicherungssumme, um Einnahmeausfälle zu kompensieren.'
              },
              {
                'icon': 'elektronikversicherung',
                'title': 'Elektronikversicherung',
                'text': 'z. B. um teure EDV- oder IT-Ausrüstung zu versichern'
              },
            ]"
            :key="item.icon"
            class="flex flex-col items-center col-12 col-md-6 col-lg-3 mb-7"
          >
            <SvgVue
              :icon="`bedarfsinfo/geschaeftsinhaltsversicherung/${item.icon}.svg`"
              class="w-1/2 mb-5"
              style=""
            />
            <div class="text-center">
              <strong v-html="item.title" /><br>
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row justify-center mb-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Tipp',
                text: 'Achten Sie beim Einschluss einer Betriebsschließungsversicherung darauf, dass auch der ' +
                  'Pandemiefall bei einer Schließung durch Einzelanordnung (z. B. Corona) im Versicherungsschutz ' +
                  'enthalten ist und der Vertrag einen offenen und dynamischen Katalog von meldepflichtigen ' +
                  'Krankheiten und Krankheitserregern enthält.',
              }]"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Wann habe ich keinen Versicherungsschutz?</h3>
            </div>

            <div class="text-center pb-7">
              <p>
                In der Regel sind folgende Gefahren vom Versicherungsschutz ausgeschlossen:
              </p>
            </div>
          </div>
        </div>

        <div class="row mb-7">
          <div class="col-8">
            <SvgVue
              icon="bedarfsinfo/hausrat/haus-leistungausschluesse.svg"
              class="block mx-auto img-fluid my-5"
              style=""
            />
          </div>

          <div class="col-4 flex flex-col justify-around">
            <div style="height: 15%" />
            <BulletPointList
              :items="[
                { text: 'Vorsatz' },
                { text: 'Krieg' },
                { text: 'Kernenergie' },
                { text: 'Sturmflut' },
                { text: 'Tsunami' },
              ]"
            />
            <div style="height: 15%" />
          </div>
        </div>

        <div class="row mb-14">
          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="[{
                title: 'Hinweis',
                text: 'Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend.<br> ' +
                  'Bei grober Fahrlässigkeit hat der Versicherer das Recht seine Leistung um einen gewissen Betrag ' +
                  'zu reduzieren.',
              }]"
            />
          </div>

          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="[{
                title: 'Hinweis',
                text: 'Einige Versicherer bieten bei ihren Inventarversicherungen die Zusatzleistung an, keinen ' +
                  'Einwand wegen grober Fahrlässigkeit zu erheben - ohne Mehrbeitrag. Die Entschädigungsleistung ' +
                  'wird bis zu einer gewissen Schadenshöhe nicht gekürzt',
              }]"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Welche Leistungen bieten Top-Tarife?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-lg-8 flex flex-col justify-between">
            <BulletPointList
              :items="[
                { text: 'Reparatur' },
                { text: 'Wiederherstellung oder Wiederbeschaffung der versicherten Sache' },
                { text: 'Löschkosten' },
                { text: 'Aufräum- und Abbruchkosten' },
                { text: 'Aufwendungen für die Wiederherstellung von Akten, Daten und Programmen' },
                {
                  text: 'Kosten durch Sicherungsmaßnahmen und den Austausch von Schlössern oder Schließanlagen ' +
                    'nach einem Einbruch'
                },
                { text: 'Kosten für die Beseitigung von Gebäudeschäden nach einem Einbruch' },
                { text: 'Sachverständigenkosten ' },
              ]"
            />
          </div>
        </div>

        <div class="row justify-center mt-7">
          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="[{
                title: 'Hinweis',
                text: 'In der Regel trägt die Inhaltsversicherung zudem die Mehrkosten einer Neuanschaffung, ' +
                  'wenn die zu ersetzende Sache aufgrund des technischen Fortschritts nicht mehr auf den ' +
                  'ursprünglich versicherten Wert zu beschaffen ist.',
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light">
      <div class="container">
        <div class="row pb-7">
          <div class="col">
            <div class="text-center">
              <h3>Was kann ich im Vorfeld unternehmen, damit der Schaden unkompliziert erstattet wird?</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 mb-7">
            <p class="mb-5">
              Sachbearbeiter der Versicherungsgesellschaften prüfen die eingereichten Schäden unter anderem
              auf Plausibilität. So wird die Versicherungsgemeinschaft vor Betrügern geschützt und die
              Beiträge bleiben für alle möglichst niedrig.
            </p>
            <p class="mb-5">
              Um im Schadenfall schnelle finanzielle Hilfe zu erhalten, ist es sinnvoll, wenn Sie den Wert
              des Geschäftsinhalts nachweisen können. Filmen oder fotografieren Sie Ihren Geschäftsinhalt
              und bewahren Sie Rechnungen von größeren Anschaffungen auf.
            </p>
            <p>
              Am besten lagern Sie diese Dokumente extern, so dass Sie jederzeit darauf Zugriff haben, falls
              es zu einem Schadenfall kommt.
            </p>
          </div>

          <div class="col-12 col-md-6">
            <div class="row">
              <div
                v-for="item in [
                  { 'icon': 'fotos-filme', 'title': 'Fotos / Filme'},
                  { 'icon': 'zeichnungen', 'title': 'Zeichnungen'},
                  { 'icon': 'prospektabbildungen', 'title': 'Prospektabbildungen'},
                  { 'icon': 'quittungen', 'title': 'Quittungen'},
                ]"
                :key="item.title"
                class="flex flex-col items-center col-12 col-md-6 mb-7"
              >
                <SvgVue
                  :icon="`bedarfsinfo/hausrat/${item.icon}.svg`"
                  class="w-1/2 mb-5"
                  style=""
                />
                <div class="text-center">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();
</script>
