import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 93.33 93.33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "46.67",
      cy: "46.67",
      r: "46.67",
      fill: "#4181D2"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "m69.87 34.3-5.43-4.65 4.46-5.21c-.15-1.74-.01.03-.14-1.8-1.81.15-.02.02-1.8.14l-4.46 5.21-5.42-4.64-28.78 33.6 4.25 3.64-6.45 7.53-3.3-2.82-2.13 2.48 9.84 8.43 2.13-2.48-2.98-2.56 5.04-5.89.02.02 1.4-1.65 4.96 4.25zM30.19 56.21l8.7-10.54 3.75 3.1-8.86 10.41zm16.07-4.45 4.22 3.48-8.7 10.54-4.38-3.62z"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }