<template>
  <SpartenWrapper
    image="images/bedarfsinfo/betriebshaftpflicht/header"
    text="Fügen Sie bei der Ausübung Ihres Berufes
          einem Dritten einen Schaden zu, so sind Sie
          verpflichtet, diesen zu ersetzen – unabhängig
          von der Schadenhöhe. Eine Betriebshaftpflicht
          nimmt Ihnen das finanzielle Risiko und wehrt
          unberechtigte Ansprüche ab."
    :versorgungen="data.bedarfsinfo.versorgungen"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie sind wir aktuell bei einem Berufshaftpflichtschaden geschützt?"
    versorgungen-description="Betriebshaftpflicht"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Wann leistet die Betriebshaftpflicht?</h2>
            </div>

            <div class="text-center pb-7">
              <p>
                Die Betriebshaftpflicht trägt die finanziellen Kosten durch Schäden, die Sie einem Dritten bei
                Ausübung Ihres Berufs unbeabsichtigt zugefügt haben.
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-7">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Schadenbeispiele - welche Kosten werden erstattet?</h3>
            </div>
          </div>
        </div>

        <div class="row items-center">
          <div class="col-12 col-md-6 col-lg-4">
            <SvgVue
              icon="bedarfsinfo/betriebshaftpflicht/sachschaden.svg"
              class="block mx-auto img-fluid my-5"
              style="width: 203px;height: 127px"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-8">
            <div>
              <BulletPoint
                :item="{
                  'title': 'Sachschaden',
                  'text': 'Aufgrund einer fehlerhaft montierten Rohrverbindung ist Leitungswasser ausgetreten und ' +
                    'hat das Parkett beschädigt.'
                }"
              />
            </div>
          </div>
        </div>

        <div class="row items-center flex-row-reverse">
          <div class="col-12 col-md-6 col-lg-4">
            <SvgVue
              icon="bedarfsinfo/betriebshaftpflicht/personenschaden.svg"
              class="block mx-auto img-fluid my-5"
              style="width: 138px;height: 169px"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-8">
            <div>
              <BulletPoint
                :item="{
                  'title': 'Personenschaden',
                  'text': 'Im Winter wurde vergessen der Räum- und Streupflicht nachzukommen, ein Fußgänger ' +
                    'rutscht aus und bricht sich das Bein.'
                }"
              />
            </div>
          </div>
        </div>

        <div class="row items-center">
          <div class="col-12 col-md-6 col-lg-4">
            <SvgVue
              icon="bedarfsinfo/betriebshaftpflicht/vermoegensschaden.svg"
              class="block mx-auto img-fluid my-5"
              style="width: 119px;height: 170px"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-8">
            <div>
              <BulletPoint
                :item="{
                  'title': 'Vermögensschaden',
                  'text': 'Ein Ziegel löst sich nach fehlerhafter Montage vom Dach und fällt auf einen Passanten. ' +
                    'In der Folge kann dieser einige Wochen nicht arbeiten und fordert eine Kompensation.'
                }"
              />
            </div>
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Welche zusätzlichen Leistungen bieten Top-Tarife?</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 flex flex-col justify-between">
            <BulletPointList
              :items="[
                { text: 'Besserstellungsklausel, so dass bessere Vertragsklauseln des Vorversicherers automatisch' +
                  ' mitversichert sind.'
                },
                { text: 'Innovationsklausel, so dass Verbesserungen der Bedingungen für Neukunden automatisch auch ' +
                  'für Bestandskunden gelten.'
                },
                { text: 'Schlüsselverlust' },
                { text: 'Haus- und Grundbesitzerhaftpflicht' },
                { text: 'Mietsachschäden' },
              ]"
            />
          </div>
          <div class="col-12 col-md-6 flex flex-col justify-between">
            <BulletPointList
              :items="[
                { text: 'Rückwärtsdeckung (in der Vergangenheit entstandene Schäden, die zum Zeitpunkt der ' +
                  'Antragstellung unbekannt sind).' },
                { text: 'Nachbesserungsbegleitschäden, so dass Schäden die infolge einer Nachbesserung entstehen ' +
                  '(z.B. falsch verlegte Rohre) ersetzt werden.' },
                { text: 'Beauftragung von Subunternehmern' },
                { text: 'Umweltschäden' },
              ]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light">
      <div class="container">
        <div class="row pb-7">
          <div class="col">
            <div class="text-center">
              <h3>Wann habe ich keinen Versicherungsschutz?</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            v-for="item in [
              {
                'icon': 'unversichertes-berufsbild',
                'text': 'Arbeiten, die nicht dem versicherten Berufsbild/der betrieblichen Tätigkeit zuzuordnen sind'
              },
              {
                'icon': 'vorsaetzliche-schaeden',
                'text': 'Vorsätzlich herbeigeführte Schäden'
              },
              {
                'icon': 'eigenschaeden',
                'text': 'Eigenschäden (alle Schäden, die Sie nicht Dritten zugefügt haben)'
              },
              {
                'icon': 'luft-kraft-wasserfahrzeuge',
                'text': 'Schäden durch Luft-, Kraft- und Wasserfahrzeuge'
              },
            ]"
            :key="item.icon"
            class="flex flex-col items-center col-12 col-md-6 col-lg-3 mb-7 lg:mb-0"
          >
            <SvgVue
              :icon="`bedarfsinfo/betriebshaftpflicht/${item.icon}.svg`"
              class="w-1/2 mb-5"
              style=""
            />
            <div class="text-center">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row justify-center my-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Info',
                text: 'Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend. ' +
                  'Sofern einzelne Leistungspunkte für Sie wichtig sind, prüfen wir gerne Sondertarife.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();
</script>
