import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 45 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M43.76.45H8.04a4.02 4.02 0 0 0 0 8.04h2.85L5.98 18.3H.89c-.24 0-.44.2-.44.45v8.92c0 .25.2.45.44.45h4.96c.23 2 1.91 3.57 3.97 3.57s3.75-1.56 3.98-3.57h17.06a4 4 0 0 0 7.95 0h4.95c.24 0 .45-.2.45-.45V.9c0-.24-.2-.44-.45-.44zM11.88 8.48h6.43v9.83H6.97l4.92-9.83zM9.82 30.81a3.13 3.13 0 1 1 0-6.26 3.13 3.13 0 0 1 0 6.26m25 0a3.13 3.13 0 1 1 .01-6.26 3.13 3.13 0 0 1 0 6.26zm8.5-3.57H38.8a4 4 0 0 0-7.95 0H13.8a4 4 0 0 0-7.95 0H1.34V19.2h17.41c.25 0 .45-.2.45-.45V8.04c0-.25-.2-.45-.45-.45H8.04a3.13 3.13 0 0 1 0-6.25H43.3v25.9z" }, null, -1 /* HOISTED */),
    _createElementVNode("path", { d: "M25.9 17.41h10.7c.25 0 .45-.2.45-.44V9.82c0-.24-.2-.44-.45-.44H25.9c-.25 0-.45.2-.45.44v7.15c0 .24.2.44.45.44m.44-7.14h9.83v6.25h-9.83z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }