<template>
  <Tab :name="name">
    <h3>Erwerbsunfähigkeitsversicherung</h3>
    <div class="my-5">
      Im Falle, dass Sie aus gesundheitlichen Gründen keiner beruflichen Tätigkeit
      mehr nachgehen können, sichert Sie diese Versicherung finanziell ab.
      Sie erhalten Leistungen...
    </div>

    <div class="mb-6">
      <BulletPointList
        small
        :items="[
          {text: '... unabhängig von der Ursache der Erkrankung.'},
          {text: '... in Form einer monatlichen Rente, deren Höhe bei Vertragsabschluss vereinbart wird.'},
        ]"
      />
    </div>

    <h3>Ab wann bin ich erwerbsunfähig?</h3>
    <div class="pb-7">
      Die gesetzliche Definition ab wann eine Erwerbsunfähigkeitsversicherung vorliegt,
      weicht von der Definition in privaten Versicherungsverträgen ab.
      Je nach Gesellschaft variieren die Bedingungen.
    </div>

    <div class="flex">
      <div class="col-12 col-lg-6 p-0">
        <h4>Beispielhafte Definition eines privaten Versicherers</h4>
        <div class="pb-2.5">
          Erwerbsunfähigkeit liegt vor, wenn die versicherte Person infolge einer Krankheit,
          Körperverletzung oder eines mehr als altersentsprechenden Kräfteverfalls,
          der die ärztlich nachzuweisen ist, voraussichtlich mindes-tens 6 Monate außerstande ist,
          einer Erwerbstätigkeit von mehr als 3 Stunden täglich nachzugehen.
        </div>
        <div class="pb-7">
          <strong>Als Erwerbstätigkeit gilt jede Tätigkeit, die auf dem allgemeinen Arbeitsmarkt üblich
            ist.</strong>
        </div>
        <h4>Erläuterung</h4>
        <div class="pb-2.5">
          Bei der Entscheidung über das Vorliegen von Erwerbsunfähigkeit kommt es ausschließlich
          auf die gesundheitlichen Verhältnisse der versicherten Person an. Irrelevant sind...
        </div>
        <div class="flex flex-wrap mb-7">
          <div
            v-for="bullet in [
              'das Verhältnis auf dem Arbeitsmarkt, insbesondere die Verfügbarkeit von Arbeitsplätzen.',
              'der zuletzt ausgeübte Beruf und die damit verbundene Tätigkeit.',
              'die bisherigen Lebens- und Einkommensverhältnisse.'
            ]"
            :key="bullet"
            class="col-12"
          >
            <BulletPoint
              small
              :item="{ text: bullet }"
            />
          </div>
        </div>
      </div>
      <div class="hidden lg:flex items-center col-6 ml-5">
        <img
          src="../../../../../../assets/images/bedarfsinfo/einkommenssicherung/persons.jpg"
          alt="Personen"
          style="max-width: 100%;"
          loading="lazy"
        >
      </div>
    </div>

    <h3>
      Benötige ich eine private Erwerbs&shy;unfähigkeits&shy;versicherung, obwohl es die
      gesetzliche Erwerbs&shy;minderungs&shy;rente
      gibt?
    </h3>
    <div class="flex flex-col lg:flex-row mb-7">
      <div class="col-12 col-lg-6 pt-7">
        <h5>Die Leistungen aus der gesetzlichen Erwerbsminderungsrente errechnen sich aus...</h5>
        <div
          v-for="bullet in [
            'den bisher erworbenen Rentenansprüchen.',
            'der schwere der gesundheitlichen Beeinträchtigung.'
          ]"
          :key="bullet"
        >
          <BulletPoint
            small
            :item="{ text: bullet }"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6 pt-7">
        <h5>Zudem gibt es Wartezeiten und Sie haben nur Anspruch, sofern Sie...</h5>
        <div
          v-for="bullet in [
            'mindestens fünf Jahre in die gesetzliche Rentenversicherung (GRV) eingezahlt haben.',
            'in den letzten fünf Jahren vor Eintritt der Erwerbsunfähigkeit mindestens drei Jahre in die GRV'
          ]"
          :key="bullet"
        >
          <BulletPoint
            small
            :item="{ text: bullet }"
          />
        </div>
      </div>
    </div>

    <div class="pb-7">
      Den genauen Anspruch auf die Erwerbsminderungsrente können Sie Ihrem Rentenbescheid entnehmen.
      Werden Sie vor dem 63. Lebensjahr erwerbsunfähig, wird ein Abschlag in Höhe von 0,3 Prozent pro Monat
      erhoben, maximal jedoch 10,8 Prozent.
      Bis zum Jahr 2024 wird die Altersgrenze zudem schrittweise auf das 65. Lebensjahr angehoben.
    </div>

    <div class="flex">
      <NiceToKnow
        classes="mb-0"
        :bullets="[
          {
            title: 'Fazit:',
            text: `Die Leistungen aus der gesetzlichen Erwerbsminderungsrente decken
                   im Regelfall nur einen Teil der tatsächlichen Lebenshaltungs-kosten.`,
          },
        ]"
      />
    </div>
  </Tab>
</template>

<script setup lang="ts">
import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import Tab from '@/application/components/Tab.vue';

interface Props {
  name: string
}

defineProps<Props>();

</script>
