import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 33 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M32.43 1.53c-.64-.4-1.32-.07-1.87.34-.23.16-.42.37-.61.57-.51.52-1 1.07-1.53 1.57a13.8 13.8 0 0 1-4.38 2.76C20.95 8 17.63 8.27 14.4 8.91c-1.3.25-2.57.61-3.76 1.21-.22.12-.35.2-.06.38 1.96.46 3.92.83 5.93.89.47.01.8.2.9.71.25 1.2.33 2.47.35 3.69 0 .8-.1 2.74-.1 2.74.1-.03.25-.08.44-.12a3.82 3.82 0 0 1 3.29 1.16c.68.86.88 2.16.46 3.18-.04.1-.3.42-.2.5.2.16.76-.32.93-.42.49-.31.98-.61 1.43-.97.74-.6 1.5-1.05 2.41-.06-.1-.6-.25-8-.22-9.08.03-.68.02-.9.27-1.53.24-.6.71-1.1 1.19-1.52l1.78-1.58c1.08-.96 2-2.02 2.81-3.22.36-.54.78-1.15.84-1.82.04-.46-.22-1.25-.65-1.52zm-9.57 21.74a6.9 6.9 0 0 1-1.88.99c-2.5.8-5.15-.17-7.43-1.22-.28-.12-.56-.18-.8-.05-.27.15-.18.47-.15.72.04.38.22.7.48.97a10.4 10.4 0 0 0 3.29 2.24c.57.24.4 1.27.4 1.75 0 1.09.03 2.18.06 3.27.6.05 4.4-.05 5.64-.28 0-1.42.1-3.46.07-4.87-.02-.7.18-.67.65-.97.65-.43 1.14-.76 1.55-1.15.67-.64 1.15-1.31 1.07-2.3-.12-1.56-2.5.56-2.95.9M9.66 8.85a3.05 3.05 0 1 0 0-6.1 3.05 3.05 0 0 0 0 6.1m10.22-2.06c1.82 0 3.3-1.48 3.3-3.3S21.43 0 19.61 0s-3.25 1.62-3.04 3.49c.2 1.8 1.48 3.3 3.3 3.3zm-.93 16.96c1.25-.1 2.26-1.04 2.26-2.32s-1.01-2.31-2.26-2.31-2.31.53-2.42 2.09c-.08 1.27.84 2.66 2.42 2.54m-6.77-.93c.06-.23.3-.55.75-.5-1.39-2.1-1.93-6.9-1.4-7.23.55-.34 3.65.07 5.03-.85.5-.33.63-1.33.37-1.78-.28-.5-.82-.52-1.33-.56-.8-.06-1.6-.19-2.38-.33-2.42-.45-4.77-1.11-6.77-2.6C4.83 7.77 3.95 6.1 3.2 4.3c-.43-1.03-.83-2.07-1.38-3.05-.2-.37-.54-.5-.95-.46-.4.05-.61.3-.74.67a2.7 2.7 0 0 0-.12 1.16 16.4 16.4 0 0 0 1.83 6.64c.52 1.02 1.29 1.92 1.64 3.02 1.94 5.97 1.25 12.78 1.05 14.63-.09.77 4.52 2.79 5.45 3.22.27.12 2 .74 2 .83a15 15 0 0 0 3.75-3.63c-.8-.6-1.68-1.09-2.46-1.72a2.8 2.8 0 0 1-1.1-2.8z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }