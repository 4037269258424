import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 36 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m33.05 21.38 2.19-.91-.46-1.1-1.32-3.1L26.7.32l-2.19.91.46 1.1-5.65 2.36-.46-1.09-1.64.68.46 1.1-7.43 3.14-.46-1.09-1.6.64.46 1.1L3 11.53l-.46-1.1-2.18.92.45 1.1 4.51 10.62-1.59.68 2.1 5.01 1.6-.68 1.45 3.42 2.19-.91-.46-1.1 5.65-2.37.46 1.1 1.64-.69-.45-1.1 7.43-3.14.45 1.1 1.64-.69-.45-1.1 5.65-2.36.4 1.14zM9.66 27.4 3.92 13.72l5.65-2.37 5.75 13.68zm7.3-3.1L11.2 10.62l7.43-3.14 5.75 13.67-7.43 3.15zm9.02-3.79L20.24 6.84l5.65-2.37 3.88 9.12 1.91 4.5z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }