<template>
  <div
    class="cursor-default"
    @click="$emit('update:modelValue', !modelValue)"
  >
    <div
      :class="{
        'OptionIcon--active': modelValue,
        'OptionButton': true,
        [`OptionButton--${iconClass}`]: true,
        'OptionIcon': true,
        'OptionIcon-animation': true,
        [$style.checkbox]: checkbox
      }"
    >
      <SvgVue
        :icon="icon"
        :class="`icon Icon Icon--${iconClass}`"
      />
    </div>
    <div class="ml-2.5 text-muted">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgVue from "@/application/components/SvgVue";

interface Props {
  modelValue: boolean;
  checkbox: boolean;
  icon: string;
  iconClass: string;
}

withDefaults(defineProps<Props>(), {
  icon: 'icomoon/check',
  iconClass: 'check',
});

defineEmits(['update:modelValue']);
</script>

<style lang="scss" module>
  .checkbox {
    border-radius: 3px;
  }
</style>
