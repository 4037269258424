<script setup lang="ts">

import { DsSkeleton } from '@demvsystems/design-components';
</script>

<template>
  <div class="flex flex-col justify-end gap-5 mt-5">
    <DsSkeleton gradient class="w-full h-24" />
    <DsSkeleton gradient class="w-full h-24" />
    <DsSkeleton gradient class="w-full h-24" />
  </div>
</template>
