import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 61 60"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd",
      stroke: "#000",
      "stroke-width": "1.5"
    }, [
      _createElementVNode("path", { d: "M4.87 58.44H56.2V22.8H4.87z" }),
      _createElementVNode("path", { d: "M.47 25.62 31.21.97l28.74 24.65M16.02 57.8h10.35V36.46H16.02zm19.83-10.89h9.42V36.56h-9.42z" }),
      _createElementVNode("path", { d: "M27.85 19.06h6.84v-7.55h-6.84z" })
    ], -1 /* HOISTED */)
  ])))
}
export default { render: render }