import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 37 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M30.89 2.27C27.1-.4 22.3-.23 18 .67a28.8 28.8 0 0 0-9.15 3.58c-.16-.16-.4-.24-.71-.24-3.98.4-6.24 3.74-7.24 7.36a16.52 16.52 0 0 0 2.8 14.15 16.44 16.44 0 0 0 10.97 6.12c5.04.68 10.05-1.83 13.95-4.8 3.82-2.9 7.51-6.72 7.6-11.81.03-2.43-.84-5.1-1.6-7.4-.75-2.06-1.95-4.1-3.73-5.36m-2 21.78c-6.2 5.52-14.78 8.07-21.7 2.3-3.25-2.7-4.76-6.63-4.8-10.77-.04-2.22.51-5.17 1.94-7.2.08.76 1.04 1.36 1.67.68 5.13-5.24 14.55-8.7 21.67-6.28 4.21 1.4 5.24 5.85 6.12 9.66 1.15 4.97-1.31 8.4-4.89 11.61z" }, null, -1 /* HOISTED */),
    _createElementVNode("path", { d: "M26.75 14.15c-5.76.2-11.48.72-17.2 1.6-1.28.2-.73 2.1.5 1.9 5.53-.83 11.1-1.31 16.66-1.51 1.32-.04 1.32-2.03.04-1.99" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }