import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 54 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M44.12 11.29c-1.11 0-2.18.19-3.18.52l-3.48-8.73 4.63-1.92a.6.6 0 0 0-.46-1.11L36.45 2.2c-.04.01-.07.04-.1.06l-.1.07-.07.1-.06.09-.02.12-.02.1.02.12c0 .04 0 .08.02.11l.67 1.68-12.66 5.1-3.4-.32-2.36-7.45h3.89a.6.6 0 1 0 0-1.2h-7.64a.6.6 0 1 0 0 1.2h2.48l3.28 10.36-2.43 1.72A10.08 10.08 0 0 0 0 20.4a10.11 10.11 0 0 0 20 2.06l1.46.22a2.5 2.5 0 0 0 1.21 1.8l-.66 2.21a.6.6 0 0 0 1.16.35l.66-2.22.11.01a2.52 2.52 0 0 0 2.16-3.8L37.45 6.31l2.38 5.95a10.1 10.1 0 1 0 4.3-.97zm-23-.61 1.42.13-1.13.8-.3-.93zM10.1 29.3a8.9 8.9 0 0 1 0-17.8 8.89 8.89 0 0 1 6.87 3.25l-7.22 5.1a.6.6 0 0 0 .26 1.09l8.79 1.34a8.91 8.91 0 0 1-8.7 7.02m8.86-8.22-7.3-1.11 6-4.24A8.83 8.83 0 0 1 19 20.4c0 .23-.02.46-.04.68m-.32-6.04 2.12-1.5 2.06 6.53c-.58.3-1.03.8-1.25 1.41l-1.41-.21a9.92 9.92 0 0 0-1.52-6.23m5.3 8.59a1.32 1.32 0 1 1 0-2.63 1.32 1.32 0 0 1 0 2.63m1.3-3.46s-.02 0-.03-.02l.66-2.21a.6.6 0 0 0-1.15-.34l-.66 2.21h-.07l-2.2-7 2.71-1.92 11.45-4.6-10.7 13.88zM44.12 30.3a8.9 8.9 0 0 1-3.85-16.9l3.3 8.23a.6.6 0 0 0 1.1-.45l-3.28-8.24a8.9 8.9 0 1 1 2.73 17.36" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }