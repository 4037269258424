import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M16.13 0A15.75 15.75 0 0 0 .4 15.74a15.75 15.75 0 0 0 31.48 0C31.88 7.06 24.8 0 16.13 0m0 30.6c-8.2 0-14.86-6.67-14.86-14.86a14.88 14.88 0 0 1 29.73 0c0 8.2-6.67 14.86-14.87 14.86" }, null, -1 /* HOISTED */),
    _createElementVNode("path", { d: "M12.76 9.25a.96.96 0 1 1-1.93 0 .96.96 0 0 1 1.93 0m8.68 0a.96.96 0 1 1-1.93 0 .96.96 0 0 1 1.93 0" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }