<template>
  <section class="section">
    <div class="container">
      <div class="text-center mb-7">
        <h2>Was passiert, wenn ich (länger) erkranke?</h2>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8 offset-lg-2 pb-14 text-center">
          Im Krankheitsfall ist Ihr Arbeitgeber für die ersten sechs Wochen verpflichtet, ihnen den
          vollen Lohn auszuzahlen. Bei längerer Erkrankung oder im Falle der Erwerbsunfähigkeit
          müssen Sie hingegen deutliche Abschläge hinnehmen.
        </div>
        <div class="col-12 col-lg-10 offset-lg-1">
          <BuChart
            :money-input="Math.max(einkommen, 0)"
            :is-freier-beruf="isFreierBeruf"
            :is-selbststaendig="isSelbststaendig"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import BuChart from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/chart/index.vue';

interface Props {
  einkommen: number,
  isFreierBeruf: boolean,
  isSelbststaendig: boolean,
}

defineProps<Props>();
</script>
