<template>
  <SpartenWrapper
    image="images/bedarfsinfo/berufshaftpflicht/header"
    text="Fügen Sie bei der Ausübung ihres Berufes
          einem Dritten einen Schaden zu, so sind Sie
          verpflichtet, diesen zu ersetzen - unabhängig
          von der Schadenhöhe. Eine Berufshaftpflicht
          nimmt Ihnen das finanzielle Risiko ab."
    :versorgungen="data.bedarfsinfo.versorgungen"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie sind wir aktuell bei einem Berufshaftpflichtschaden geschützt?"
    versorgungen-description="Berufshaftpflicht"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Wann leistet die Berufshaftpflicht?</h2>
            </div>

            <div class="text-center pb-7">
              <p>
                Die Berufshaftpflicht trägt die finanziellen Kosten durch Schäden, die Sie einem
                Dritten bei Ausübung Ihres Berufs unbeabsichtigt zugefügt haben.
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-7">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="text-center pb-7">
              <h3>Schadenbeispiele - welche Kosten werden erstattet?</h3>
            </div>
          </div>
        </div>

        <div class="row items-center">
          <div class="col-12 col-md-6 col-lg-4">
            <SvgVue
              icon="bedarfsinfo/berufshaftpflicht/sachschaden.svg"
              class="block mx-auto img-fluid my-5"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-8">
            <div>
              <BulletPoint
                :item="{
                  'title': 'Sachschaden',
                  'text': 'Ein Mitarbeitender entleert den Aschenbecher kurz vor Verlassen der Praxis. ' +
                    'Die Glut entzündet ein Feuer, das sich in der Praxis ausbreitet und die angrenzenden ' +
                    'Büros stark beschädigt.'
                }"
              />
            </div>
          </div>
        </div>

        <div class="row items-center flex-row-reverse">
          <div class="col-12 col-md-6 col-lg-4">
            <SvgVue
              icon="bedarfsinfo/berufshaftpflicht/personenschaden.svg"
              class="block mx-auto img-fluid my-5"
              style=""
            />
          </div>

          <div class="col-12 col-md-6 col-lg-8">
            <div>
              <BulletPoint
                :item="{
                  'title': 'Personenschaden',
                  'text': 'Im Büro stolpert ein Kunde über ein offen verlegtes Verlängerungskabel und bricht ' +
                    'sich dabei ein Bein. Die Berufshaftpflicht übernimmt die Behandlungs- und Folgekosten und…'
                }"
              />
            </div>
          </div>
        </div>

        <div class="row items-center">
          <div class="col-12 col-md-6 col-lg-4">
            <SvgVue
              icon="bedarfsinfo/berufshaftpflicht/unechter-vermoegensschaden.svg"
              class="block mx-auto img-fluid my-5"
              style=""
            />
          </div>

          <div class="col-12 col-md-6 col-lg-8">
            <div>
              <BulletPoint
                :item="{
                  'title': 'Unechter Vermögensschaden',
                  'text': '… darüber hinaus das Schmerzensgeld sowie die Kosten für ' +
                    'den Verdienstausfall der verletzten Person.'
                }"
              />
            </div>
          </div>
        </div>

        <div class="row justify-center mt-14 mb-0">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow
              classes="mb-0"
              :bullets="[{
                title: 'Info',
                text: 'Zusätzlich weist die Versicherung unberechtigte Forderungen ab und setzt dieses ' +
                  'gerichtlich durch (sogenannter passiver Rechtsschutz).'
              }]"
            />
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Welche zusätzlichen Leistungen bieten Top-Tarife?</h3>
            </div>
          </div>
        </div>
        <div class="row justify-center">
          <div class="col-lg-10">
            <div class="row">
              <div class="col-md-6">
                <BulletPointList
                  :items="[
                    {
                      text: 'Rückwärtsdeckung (in der Vergangenheit entstandene Schäden, die zum Zeitpunkt ' +
                        'der Antragstellung unbekannt sind).'
                    },
                    { text: 'Haftung gemäß dem allgemeinen Gleichbehandlungsgesetz (AGG).' },
                    { text: 'Vertrauensschäden durch Mitarbeiter.' },
                  ]"
                />
              </div>
              <div class="col-md-6">
                <BulletPointList
                  :items="[
                    { text: 'Verlust schriftlicher Arbeitsdokumente.' },
                    { text: 'Domainschutzversicherung.' },
                  ]"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Wann habe ich keinen Versicherungsschutz?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-lg-10">
            <div class="row">
              <div class="col-md-6">
                <BulletPointList
                  :items="[
                    { text: 'Bei echten Vermögensschäden (tarifabhängig).' },
                    { text: 'Bei Eigenschäden, die Sie sich selbst oder einer mitversicherten Person zufügen.' },
                    { text: 'Bei vorsätzlich herbeigeführten Schäden.' },
                  ]"
                />
              </div>
              <div class="col-md-6">
                <BulletPointList
                  :items="[
                    { text: 'Bei Schäden an gemieteten oder geliehenen Gegenständen.'},
                    {
                      text: 'Bei Schäden infolge von Veruntreuung oder wissentliches Abweichen von ' +
                        'Vorschriften oder Gesetzen.',
                    },
                  ]"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-6">
            <NiceToKnow
              classes=""
              :bullets="[{
                title: 'Info:',
                text: 'Ein sogenannter echter Vermögensschaden entsteht, wenn der Vermögensschaden ohne ' +
                  'zusätzlichen Personen- oder Sachschaden entstanden ist. Dieses kann über eine separate ' +
                  'Vermögensschadenhaftpflicht versichert werden und ist für alle beratenden Berufe sehr wichtig.'
              }]"
            />
          </div>
          <div class="col-md-6">
            <NiceToKnow
              classes=""
              :bullets="[{
                title: 'Info:',
                text: 'Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend. ' +
                  'Sofern einzelne Leistungspunkte für Sie wichtig sind, prüfen wir gerne Sondertarife.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { UnfallInfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: UnfallInfo,
}

defineProps<Props>();
</script>
