<template>
  <div class="container">
    <div class="row pb-7">
      <div class="col text-center">
        <h2 class="hyphens-auto">
          {{ headline ?? 'Wie bin ich aktuell versichert?' }}
        </h2>
      </div>
    </div>

    <div
      v-for="({ ziele, bewertung, zuordnungen }, i) in versorgungen"
      :key="i"
      class="row"
      :class="{'mt-10': i > 0}"
    >
      <div class="col-12 col-lg-8">
        <h3
          v-if="description || zuordnungen?.sparte?.label "
          class="font-semibold text-base md:text-xl hyphens-auto"
        >
          {{ zuordnungen?.sparte?.label ?? description }}
        </h3>

        <div class="table-responsive overflow-x-auto mb-3">
          <table class="table Table mb-0">
            <tr>
              <th>Leistungsmerkmal</th>
              <th>Ziel</th>
              <th>Ihre Absicherung</th>
              <th>Lücke</th>
            </tr>
            <tr
              v-for="{
                leistung,
                luecke,
                vorgabe,
                vorhandeneAbsicherung
              } in ziele.ziele"
              :key="leistung"
            >
              <td>{{ leistung }}</td>
              <td class="whitespace-nowrap">{{ vorgabe.text }}</td>
              <td class="whitespace-nowrap">{{ vorhandeneAbsicherung.text }}</td>
              <td class="whitespace-nowrap">{{ luecke.text }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <h5 class="mb-2.5">
          Erreichte Versicherungs&shy;abdeckung:
          <DsBadge type="primary">{{ ziele.zielerreichung }} %</DsBadge>
        </h5>
        <template v-if="bewertung?.handlungstext?.kommentar">
          <h5>Unsere Empfehlung</h5>
          {{ bewertung.handlungstext.kommentar }}
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Versorgung } from '@/bedarf/types/dtos';
import { DsBadge } from '@demvsystems/design-components';

interface Props {
  headline?: string
  description?: string
  versorgungen?: Versorgung[]
}

withDefaults(defineProps<Props>(), {
  headline: undefined,
  description: undefined,
  versorgungen: () => [],
});
</script>

<style>
.Table {
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
  margin-bottom: 20px;
  max-width: 100%;
  width: 100%
}

.Table td, .Table th {
  padding: 8px
}

.Table th {
  background-color: #4181d2;
  color: #fff;
  font-weight: 400
}

.Table td {
  background-color: #f7f7f7
}

.Table tr:nth-child(2n) td {
  background-color: #ececec
}
</style>
