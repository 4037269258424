<template>
  <div
    class="flex flex-col sm:flex-row gap-2 sm:gap-5 p-5 hyphens-auto rounded-xl mb-5 bg-blue-500 bg-opacity-5
    border-2 border-blue-500"
    :class="classes"
  >
    <div class="text-center">
      <SvgVue
        class="inline-block fill-blue-500 h-10 w-10"
        icon="bedarfsinfo/nice-to-know"
      />
    </div>
    <div class="flex-initial">
      <p
        v-for="{title, text} in bullets"
        :key="title"
        class="last:mb-0"
      >
        <b>{{ title }}</b><br>
        <span v-html="text" />
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';

type BulletPoint = { title: string, text: string };

interface Props {
  bullets: BulletPoint[]
  classes?: string,
}

withDefaults(defineProps<Props>(), {
  classes: '',
});

</script>
