import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M50 .9C22.7.9.6 23 .6 50.3S22.7 99.8 50 99.8s49.4-22.1 49.4-49.4S77.3.9 50 .9m22.1 9.7c.4-.5 1.8 2.4 1.8 3.3s-.6 1.7-1.5 1.7c-.9.1-1.7-.6-1.7-1.5-.1-.9.9-3 1.4-3.5m3.4 20.1c.6-.7 2.6 3.5 2.7 4.8a2.35 2.35 0 1 1-4.7.3c-.1-1.3 1.3-4.4 2-5.1m-8.1-11.3c.6-.7 2.6 3.5 2.7 4.8a2.35 2.35 0 1 1-4.7.3c-.1-1.3 1.4-4.3 2-5.1M57.9 12c.4-.5 1.8 2.4 1.8 3.3.1.9-.6 1.7-1.5 1.7s-1.7-.6-1.7-1.5 1-3 1.4-3.5m19.4 42.8-3.1-2V79H24.9V52.8l-3.1 2-1.8-6.6 29.5-18.9L79 48.1zm.8-28.1c-1.3.1-2.4-.9-2.5-2.2s1.4-4.4 2-5.1 2.6 3.5 2.7 4.8c0 1.3-.9 2.5-2.2 2.5m12.4 19.6c-2 .1-3.7-1.4-3.8-3.3-.1-2 2.1-6.6 3.1-7.8 1-1.1 3.9 5.3 4.1 7.3s-1.5 3.7-3.4 3.8"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }