<template>
  <SpartenWrapper
    image="images/bedarfsinfo/vermoegensschadenhaftpflicht/header"
    text="Fügen Sie bei der Ausübung Ihres Berufes
          einem Dritten einen Schaden zu, so sind Sie
          verpflichtet, diesen zu ersetzen – unabhängig
          von der Schadenhöhe. Eine Vermögensschadenhaftpflicht
          nimmt Ihnen das finanzielle Risiko ab."
    :versorgungen="data.bedarfsinfo.versorgungen"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie gut sind wir  bei einem Vermögensschaden geschützt?"
    versorgungen-description="Vermögensschadenhaftpflicht"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Wann leistet die Vermögensschadenhaftpflicht?</h2>
            </div>

            <div class="text-center pb-7">
              <p>
                Die Vermögensschadenhaftpflicht trägt die finanziellen Kosten durch Vermögensschäden, die Sie einem
                Dritten bei Ausübung Ihres Berufs unbeabsichtigt zugefügt haben.
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-7">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Worin unterscheiden sich echte von unechten Vermögensschäden?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-lg-8 col-md-10 flex flex-col justify-center">
            <BulletPointList
              :items="[
                {
                  text: 'Bei unechten Vermögensschäden muss dem Schaden ein Sach- oder Personenschaden ' +
                    'voraus- gegangen sein.'
                },
                {
                  text: 'Ein echter Vermögensschaden kann auch unabhängig von einem Sach- ' +
                    'oder Personenschaden entstehen.'
                },
                {
                  text: 'Bei einer Vermögensschadenhaftpflicht sind auch echte Vermögensschäden mitversichert.'
                },
              ]"
            />
          </div>
        </div>

        <div class="row justify-center mt-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Info',
                text: 'Zusätzlich weist die Versicherung unberechtigte Forderungen ab und setzt dieses ' +
                  'gerichtlich durch (sogenannter passiver Rechtsschutz).',
              }]"
            />
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Schadenbeispiele - welche Kosten werden erstattet?</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <BulletPointList
              :items="[
                {
                  'title': 'Mediziner',
                  'text' : 'Durch einen Behandlungsfehler eines Arztes erleidet der Patient ein Leben lang Schmerzen.'
                },
                {
                  'title': 'Rechtsanwalt',
                  'text' : 'Bei der Vertretung eines Mandanten kann aufgrund organisatorischer Schwierigkeiten in ' +
                    'der Kanzlei die finale Frist gegen einen Bußgeldbescheid nicht einhalten werden.'
                },
                {
                  'title': 'Steuerberater',
                  'text' : 'Ein Steuerberater beachtet neue oder geänderte Steuervorschriften nicht. Dadurch ' +
                    'erleidet sein Mandant erhebliche finanzielle Nachteile, welche dieser gegenüber dem ' +
                    'Steuerberater geltend macht.'
                },
              ]"
            />
          </div>
          <div class="col-12 col-md-6">
            <BulletPointList
              :items="[
                {
                  'title': 'Ingenieur',
                  'text' : 'Ein Landschaftsplaner wurde mit der Planung eines Stadtparks beauftragt. ' +
                    'Bei der Planung werden besondere Auflagen aufgrund eines geotechnischen Gutachtens ' +
                    'missachtet. Bis zum Baustart fällt der Irrtum nicht auf. Dann steht der Bau ' +
                    'still. Der Auftraggeber fordert wegen der Fehlplanung Schadenersatz.'
                },
                {
                  'title': 'Architekt',
                  'text' : 'Ein Architekt wird beauftragt den Plan eines Subunternehmers in den ' +
                    'Gesamt-Bauplan zu übertragen. Dabei passierte ein Fehler, der dafür sorgte, ' +
                    'dass das Fundament zu groß gegossen wurde und es anschließend komplett ' +
                    'abgetragen werden musste. Der Architekt haftet für diesen Schaden.'
                },
                {
                  'title': 'IT-Dienstleister',
                  'text' : 'Ein IT-Dienstleister macht einen Fehler bei der Programmierung eines ' +
                    'Onlineshops. Der Shop ist tagelang nicht erreichbar. Der IT-Dienstleister ist ' +
                    'für den Umsatzausfall verantwortlich.'
                },
              ]"
            />
          </div>
        </div>
      </div>
    </section>

    <JumboImage
      image="images/bedarfsinfo/vermoegensschadenhaftpflicht/section-separator"
      alt="Mann sitzt an Schreibtisch und stapelt Münzen"
    />

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Wann habe ich keinen Versicherungsschutz?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-12 col-md-6 flex flex-col justify-center">
            <BulletPointList
              :items="[
                { text: 'Bei Eigenschäden, die Sie sich selbst oder einer mitversicherten Person zufügen.' },
                { text: 'Bei vorsätzlich herbeigeführten Schäden.' },
                { text: 'Bei Schäden an gemieteten oder geliehenen Gegenständen.' },
              ]"
            />
          </div>
        </div>

        <div class="row justify-center mt-14 mb-0">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Info',
                text: 'Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend.' +
                  ' Sofern einzelne Leistungspunkte für Sie wichtig sind, prüfen wir gerne Sondertarife.',
              }]"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import JumboImage from '@/bedarf/components/JumboImage.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();
</script>
