<template>
  <div
    v-if="number !== null && number > 0"
    class="unread-hint inline-block bg-red-100 text-red-700 absolute px-1.5"
  >
    {{ number > 99 ? '99+' : number }}
  </div>
</template>

<script setup lang="ts">
interface Props {
  number: number | null,
}

defineProps<Props>()
</script>

<style>
.unread-hint {
  height: 18px;
  top: -4px;
  right: 0;
  font-size: 12px;
  transform: translateX(20px);
  line-height: 20px;
  border-radius: 9px;
  min-width: 20px;
  font-weight: bold;
  text-align: center;
}
</style>
