<template>
  <div
    style="margin-top: -4px; margin-bottom: -4px;"
    :title="sparte"
  >
    <span class="text-gray-800 font-semibold">{{ sparte }}</span>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  sparte: string,
}

defineProps<Props>();
</script>
