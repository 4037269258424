<script setup lang="ts">

import { formatDecimal } from '@/application/utils/money';

interface Props {
  hasContracts: boolean;
  contractsCount: number;
  totalCostPerMonth: number;
}

defineProps<Props>();
</script>

<template>
  <div class="flex gap-3">
    <span v-if="!hasContracts" class="text-xs md:text-sm px-4 text-gray-500">Es liegen zur Zeit keine Verträge von Ihnen vor.</span>
    <span v-if="hasContracts" class="text-xs md:text-sm text-gray-800">
      Aktive Verträge
      <span
        class="font-medium inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs md:text-sm text-green-800 ring-1 ring-inset ring-green-500/30 ml-1"
      >
        {{ contractsCount }}
      </span>
    </span>
    <span v-if="hasContracts" class="text-xs md:text-sm text-gray-800">
      Gesamtkosten
      <span
        class="font-medium inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs md:text-sm text-green-800 ring-1 ring-inset ring-green-500/30"
      >
        {{ formatDecimal(totalCostPerMonth) }}
      </span> / Monat
    </span>
  </div>
</template>
