<template>
  <DsModal
    :show="show"
    hide-buttons
    @cancel="() => {
      $emit('close')
    }"
  >
    <ModalWrapper>
      <DocumentUpload
        style="max-height: calc(100vh - 7rem)"
        @close="$emit('close')"
      />
    </ModalWrapper>
  </DsModal>
</template>

<script setup lang="ts">

import { DsModal } from '@demvsystems/design-components';

import DocumentUpload from '@/upload/components/UploadView.vue';
import ModalWrapper from "@/application/components/ModalWrapper.vue";

interface Props {
  show: boolean;
}

defineProps<Props>();

defineEmits(['close']);

</script>
