import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 45 45"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "22.4",
      cy: "22.4",
      r: "21.6",
      fill: "#4181D2"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "M27.2 35.7h-1.9l.1.5 1.8-.2zm-2.7-3.1 2 .1c0-.2-.1-.4-.1-.6l-2.1-.1zm2.1.5-2-.1.3 1zM23.5 29l2.4.1c-.1-.5-.1-.9-.2-1.4l-2.4.6zm-.9-17.9a2.8 2.8 0 1 0 .01-5.59 2.8 2.8 0 0 0-.01 5.59m1 18.3.1.5 2.3.1c0-.2 0-.3-.1-.5zm1.3 4.9.3 1h1.9c-.2-.6-.3-1.2-.4-1.8z",
      style: {"fill":"#fff"}
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "m27.7 24.9.4-1.4c.1 0-.6-4.1-.7-6.1-.3-4.6-2-5.7-2-5.7l-2.8-.1s-2.5-.4-3.1 0l-3.4 4.8 3.1 3.5c-.6 6.8-1.6 16-1.7 17-.1 0-1.5.6-1.6 1.3s.2 1.3.2 1.3l2.1-.4 1.1-.9v-1.3L22 24.5h.3l1 3.6 2.4-.6c-.6-5.7-.3-10.3-.1-12.6l.5 2.7.6 5.4v.1c-.2.1-.3.3-.3.5L28.8 38c-.5-.5-1.3-.8-1.3-.8-.1-.2-.1-.4-.2-.6l-1.8.2.1.5v1.3l1.1.9 2.1.4s.1-.2.1-.5l.1.6c0 .2.3.4.5.4h.1c.3 0 .5-.3.4-.6zm-8.4-7-1.5-1.6 1.7-1.6c0 1-.1 1.7-.2 3.2",
      style: {"fill":"#fff"}
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "m24.1 31.2.1.4 2.1.1c-.1-.3-.1-.6-.2-1zm-.2-.9.1.5 2-.4z",
      style: {"fill":"#fff"}
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }