import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M16 31.96c8.8 0 15.96-7.16 15.96-15.96S24.8.04 16 .04.04 7.2.04 16 7.2 31.96 16 31.96M16 .93a15.08 15.08 0 0 1 0 30.13A15.07 15.07 0 1 1 16 .93" }, null, -1 /* HOISTED */),
    _createElementVNode("path", { d: "M12.58 10.31a.98.98 0 1 1-1.96 0 .98.98 0 0 1 1.96 0m8.8 0a.98.98 0 1 1-1.96 0 .98.98 0 0 1 1.96 0m-9.74 11.56c0 2.3 1.91 4.22 4.23 4.22s4.22-1.91 4.22-4.22-1.91-4.23-4.22-4.23-4.23 1.92-4.23 4.23m4.23-3.34c1.82 0 3.33 1.51 3.33 3.34s-1.51 3.33-3.33 3.33-3.34-1.51-3.34-3.33 1.47-3.34 3.34-3.34" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }