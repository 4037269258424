<template>
  <DsModal
    :show="show"
    hide-buttons
    @cancel="$emit('close')"
  >
    <ModalWrapper>
      <div class="flex">
        <DsButton
          class="ml-auto"
          variant="clear"
          icon="xmark-large"
          icon-variant="regular"
          @click="$emit('close')"
        />
      </div>
      <OnboardingBody @close="$emit('close')" />
    </ModalWrapper>
  </DsModal>
</template>

<script setup lang="ts">
import { DsButton, DsModal } from '@demvsystems/design-components';

import OnboardingBody from '@/application/components/OnboardingBody.vue';
import ModalWrapper from "@/application/components/ModalWrapper.vue";

interface Props {
  show: boolean
}

defineProps<Props>();

defineEmits(['close', 'open']);
</script>
