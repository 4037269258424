<template>
  <h3
    v-if="title !== undefined"
    class="mb-7"
  >
    {{ title }}
  </h3>

  <p v-if="description !== undefined">
    {{ description }}
  </p>

  <BulletPoint
    v-for="(item, i) in items"
    :key="i"
    :inline-title="inlineTitle"
    :item="item"
    :title="title"
    :small="small"
  />
</template>

<script setup lang="ts">
import BulletPoint from './BulletPoint.vue';
import type { BulletPointItem } from './types';

interface Props {
  title?: string,
  description?: string,
  inlineTitle?: boolean,
  items: BulletPointItem[],
  small?: boolean,
}

defineProps<Props>();
</script>
