import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1416 1176"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "713.9",
      cy: "583.7",
      r: "571.1",
      fill: "#4181D2"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "M580.8 563.3c75.5 0 136.8-61.2 136.8-136.8 0-75.5-61.2-136.8-136.8-136.8S444 351 444 426.5s61.3 136.8 136.8 136.8m0 34.1c-141.2 0-255.7 138.5-255.7 309.2 203.2 4.7 255.6 5.9 511.4 0 0-170.8-114.5-309.2-255.7-309.2",
      style: {"fill":"#fff"}
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "M1109.2 286.2H657.1c20.3 12.4 39.4 28.5 48 46l8.7-.1 377.3.1v107.4H746.4v27.9h87.1v112.9h-85.8v27.9h85.8v101.8h-29.1c4.4 8.1 23 43.9 24 46h280.7c15.4 0 27.9-12.5 27.9-27.9V314.1c.1-15.3-12.4-27.9-27.8-27.9M974.3 710.1H862.1V608.4h112.2zm0-129.6H862.1v-113h112.2zm116.8 129.6h-88.2V608.4h88.2zm0-129.6h-88.2v-113h88.2z",
      style: {"fill":"#fff"}
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }