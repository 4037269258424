import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 39 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.6 4.74C2.76 8.75-3.55 18.76 2.58 26.16c2.8 3.34 6.9 4.51 11.1 5.05 5.18.68 10.69.5 15.47-1.67.72-.31.72-.99.36-1.49 3.29-2.97 6.27-6.18 8.03-10.32C39.19 13.7 39 8.6 36.22 5.14 29.45-3.06 17.46.18 9.6 4.74m25.26 13.48c-1.8 3.84-5.23 6.81-8.39 9.52a.92.92 0 0 0-.36.58c-3.38 1.04-6.94 1.04-10.5.82-3.8-.23-7.76-1-10.6-3.66-2.85-2.61-2.94-6.85-1.85-10.32 1.21-3.97 4.78-6.86 8.2-8.89C18.1 2.35 28.2-.31 34.2 6.18c3.02 3.34 2.39 8.34.67 12.04z" }, null, -1 /* HOISTED */),
    _createElementVNode("path", { d: "M29 13.8c-2.84.1-5.64.27-8.48.5V6.18c0-1.44-2.25-1.44-2.25 0v8.3c-2.93.27-5.87.63-8.8 1.08-1.44.23-.81 2.39.59 2.17 2.7-.41 5.46-.77 8.16-1v8.66c0 1.45 2.26 1.45 2.26 0v-8.88c2.84-.23 5.63-.4 8.48-.5 1.48 0 1.48-2.25.04-2.2z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }