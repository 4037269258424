import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 44 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M18.18 9.3h-5.96l-3.28 6.03h5.95zm7.86-1.44 3.9-7.15L24.48.7l-3.9 7.17zm-.78 1.44h-5.47l-3.29 6.03h5.47zM12.44 22.79h5.46l3.29-6.03h-5.47zm-.79 1.43L7.5 31.87l5.45.01 4.18-7.66zm2.45-7.46H8.17l-3.28 6.02h5.94zm-10 7.46-4.16 7.62 5.93.02 4.17-7.64zM29 16.76h-6.2l-3.28 6.02h6.19zM22.87.69 16.92.67l-3.91 7.2h5.95zm10.97 7.17L37.72.74 31.55.72l-3.9 7.14zm-.78 1.44h-6.2l-3.28 6.03h6.2z" }, null, -1 /* HOISTED */),
    _createElementVNode("path", { d: "M42.46 26.44h-4.4v-2.3h4.4c.58 0 1.05-.47 1.05-1.04s-.47-1.04-1.05-1.04h-4.4v-3.28c-3.52 0-6.4 2.36-6.92 5.45h-12.4l-4.19 7.66 6.19.02L23.96 26h7.17c.45 3.14 3.36 5.58 6.94 5.58v-3.06h4.4c.57 0 1.04-.47 1.04-1.04s-.47-1.04-1.05-1.04" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }