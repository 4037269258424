<template>
  <a
    v-if="action !== null"
    class="text-center relative hover:no-underline cursor-pointer"
    :style="`flex-basis: ${width}%;`"
    @click.prevent="action"
  >
    <MenuItemIconText
      :title="title"
      :icon="icon"
      :active-icon="activeIcon"
      :unread-hints="unreadHints"
    />
  </a>

  <RouterLink
    v-else-if="to !== null"
    v-slot="{ href, navigate, isActive }"
    :to="to()"
    custom
  >
    <a
      class="text-center relative hover:no-underline"
      :style="`flex-basis: ${width}%;`"
      :href="href"
      @click="navigate"
    >
      <MenuItemIconText
        :title="title"
        :icon="icon"
        :active-icon="activeIcon"
        :unread-hints="unreadHints"
        :is-active="isActive"
      />
    </a>
  </RouterLink>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';
import { RouterLink } from 'vue-router';
import MenuItemIconText from "@/application/menu/components/MenuItemIconText.vue";

export type Props = {
  title: string,
  icon: string | string[],
  activeIcon: string | string[],
  to?: null | (() => RouteLocationRaw),
  action?: null | (() => void),
  unreadHints?: number | null,
  width: number,
};

withDefaults(defineProps<Props>(), {
  to: null,
  action: null,
  id: null,
  unreadHints: null,
});
</script>
