<template>
  <div class="relative py-5">
    <SvgVue
      class="absolute max-h-80"
      style="opacity: 0.32; left: 0; right: 0; margin: 0 auto"
      :icon="svgIcon"
    />

    <template
      v-for="(item, i) in items"
      :key="item"
    >
      <div
        v-if="i > 0"
        class="text-center pt-1.5 pb-2.5"
      >
        <FontAwesomeIcon
          icon="arrow-down"
          size="2x"
          style="color: #2360B0"
        />
      </div>

      <BulletPoint :item="item" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import type { BulletPointItem } from '@/bedarf/components/types';
import SvgVue from "@/application/components/SvgVue";

type FlowListItem = Omit<BulletPointItem, 'title'>;

interface Props {
  items: FlowListItem[]
  svgIcon: string,
}

defineProps<Props>();
</script>
