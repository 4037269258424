import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 49.7 98.5"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#4181D2",
      d: "M12.8 0h34v34h-34z"
    }, null, -1 /* HOISTED */),
    _createElementVNode("circle", {
      cx: "33.4",
      cy: "32.1",
      r: "5.8",
      class: "cls-2"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "m49.7 35.1-2.9-7.9v6.6l-7.6.2s-.4 2.8-4.1 4.2c0 0-5.2 1.7-7.5-4.2l-14.5-.3V23.4s-2.5 3-2.7 14.1l10.3 1 1.7 2.7-1 14-5 12.8-13 16.4s-3.6.4-3.4 1.8l1 4.4L2.6 93l2.9 1.2 1.7-.8-2.3-4 .3-2L21.6 71l6-9.2L35 76l-2 17s-1.2 2.4-.7 3.4 6 1.6 6 1.6l2.8.5.5-.8-.4-1.6-5.4-2.6 4.4-19.3-7.4-15 6-19.5s11.3-3.5 10.9-4.6",
      class: "cls-2"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }