<template>
  <div class="bg-light w-full">
    <div class="container">
      <div class="row justify-center">
        <div class="col col-md-10">
          <h3 class="my-2.5">
            iOS Installation
          </h3>

          <ol>
            <li class="mb-5">
              Klicken Sie auf den '<strong>Teilen</strong>'-Button
              <img
                :src="Iphone1"
                loading="lazy"
                alt=""
                class="max-w-full block"
              >
            </li>

            <li class="mb-5">
              Wählen Sie die Option '<strong>Zum Home-Bildschirm</strong>'
              <img
                :src="Iphone2"
                loading="lazy"
                alt=""
                class="max-w-full block"
              >
            </li>

            <li class="mb-5">
              Klicken Sie auf '<strong>Hinzufügen</strong>'
              <img
                :src="Iphone3"
                loading="lazy"
                alt=""
                class="max-w-full block"
              >
            </li>

            <li class="mb-5">
              Der Finanzmanager kann jetzt über das App-Symbol gestartet werden
              <img
                :src="Iphone4"
                loading="lazy"
                alt=""
                class="max-w-full block"
              >
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Iphone1 from '../../../../assets/iPhone/1.png'
import Iphone2 from '../../../../assets/iPhone/2.png'
import Iphone3 from '../../../../assets/iPhone/3.png'
import Iphone4 from '../../../../assets/iPhone/4.png'
</script>
