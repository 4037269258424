import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 34 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M33.76 27.11H29.8l-1.52-1.51h3.42a.3.3 0 0 0 .2-.08.27.27 0 0 0-.2-.45h-3.95l-1.52-1.52h3.43c.07 0 .14-.03.18-.08a.26.26 0 0 0-.18-.45h-3.95l-8.19-8.18 13.7-13.69-.77 2.86a.26.26 0 1 0 .52.13l1.01-3.8.01-.07V.2l-.01-.02-.02-.04V.13l-.04-.05-.05-.04h-.02l-.04-.02h-.02L31.73 0l-.06.01h-.01l-3.8 1.02a.27.27 0 0 0 .13.51l2.85-.76-13.68 13.68L3.47.78l2.86.76a.26.26 0 1 0 .13-.5L2.66 0H2.5l-.04.02-.02.01-.05.04-.04.05v.02l-.02.04-.01.02v.12l1.02 3.81A.27.27 0 0 0 3.85 4L3.1 1.15l13.68 13.69-8.18 8.18H4.65c-.15 0-.27.12-.27.26s.12.27.27.27h3.42l-1.51 1.52H2.6a.26.26 0 1 0 0 .52h3.43L4.5 27.11H.56c-.15 0-.27.12-.27.27s.12.26.27.26h3.8v3.8a.26.26 0 0 0 .26.26c.15 0 .26-.12.26-.26v-3.95l1.52-1.52v3.42a.26.26 0 0 0 .27.27c.14 0 .26-.12.26-.27v-3.95l1.52-1.52v3.43c0 .07.03.14.07.18.05.05.12.08.2.08.14 0 .26-.12.26-.26v-3.96l8.18-8.18 8.18 8.18v3.96c0 .14.12.26.26.26s.27-.12.27-.26v-3.43l1.52 1.52v3.95c0 .15.11.27.26.27s.26-.12.26-.27v-3.42l1.52 1.52v3.95a.26.26 0 0 0 .53 0v-3.8h3.8a.26.26 0 0 0 .26-.26.26.26 0 0 0-.26-.27" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }