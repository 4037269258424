<template>
  <SpartenWrapper
    image="images/bedarfsinfo/photovoltaik/Titelbild"
    text="Kommt es zur Beschädigung der Photovoltaikanlage, können hohe Kosten für die Reparatur
          entstehen. Zudem kann kein Strom mehr
          eingespeist werden und es kommt zu einem
          Einnahmeausfall. Eine Photovoltaikversicherung
          übernimmt die entstehenden Kosten."
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie gut ist meine Photovoltaikanlage versichert?"
    versorgungen-description="Photovoltaik"
  >
    <section class="section">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <h3 class="mb-7">
              Wann leistet die Photovoltaikversicherung?
            </h3>
            <p class="mb-0">
              Eine eigenständige Photovoltaikversicherung bietet eine sogenannte
              Allgefahrendeckung
              (sofern die Basis eine Elektronikversicherung ist).
            </p>
            <p class="mb-0">
              Somit sind alle Schadenereignisse die nicht ausdrücklich
              ausgeschlossen sind, automatisch mitversichert.
            </p><p class="mb-0">
              Mitversichert sind somit meist Beschädigungen
              oder Zerstörungen (Sachschaden) durch:
            </p>
          </div>
        </div>
        <div class="row mt-7">
          <div class="col-12">
            <div class="row items-start mb-7">
              <div class="col-md col-sm">
                <List>Bedienungsfehler, Ungeschicklichkeit, Fahrlässigkeit</List>
                <List>Konstruktions- oder Materialfehler</List>
                <List>Vorsatz Dritter, Sabotage, Vandalismus, Diebstahl</List>
              </div>
              <div class="col-md col-sm">
                <List>Überspannung, Induktion, Kurzschluss</List>
                <List>Brand, Blitzschlag, Explosion sowie Schwelen, Glimmen, Sengen, Glühen oder Implosion</List>
                <List>Wasser, Feuchtigkeit, Überschwemmung</List>
              </div>
              <div class="col-md col-sm">
                <List>Naturereignisse wie z. B. Sturm, Blitz, Hagel, Schneedruck, Frost</List>
                <List>Höhere Gewalt</List>
                <List>Tierbiss z. B. durch einen Mader</List>
              </div>
            </div>
            <div class="row items-center justify-center mb-7">
              <SvgVue
                icon="bedarfsinfo/photovoltaik/Photovoltaik-anlagen.svg"
                class="mx-md-auto px-14 img-fluid"
                style="height: 200px;"
              />
            </div>
          </div>
        </div>
        <div class="row mt-7">
          <div class="col-12 col-md-6 pb-7 md:pb-0">
            <NiceToKnow
              classes="h-full"
              :bullets="[{
                title: 'Hinweis:',
                text: 'In einzelnen Tarifen können die aufgezählten Leistungen explizit ausgeschlossen sein.' +
                  ' Zudem können Leistungsobergrenzen die maximale Entschädigung deckeln.'
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mb-0"
              :bullets="[{
                title: 'Hinweis:',
                text: 'Gute Tarife leisten auch bei Schäden an allen Anlagenteilen, die für die einwandfreie ' +
                  'Funktion der Photovoltaikanlage notwendig sind. Dazu gehören beispielsweise Wechselrichter, ' +
                  'Zähler und das Montagesystem inklusive der Anschlüsse.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section pt-0">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <h3 class="mb-5">
              Welche Schäden sind ausgeschlossen?
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 offset-md-3 col-sm">
            <p class="mx-auto">
              Vom Versicherungsschutz ausgeschlossen sind im Regelfall&hellip;
            </p>
            <p class="mb-1.5">
              &hellip; Schäden durch betriebsbedingte normale oder vorzeitige Abnutzung und
              Alterung sowie
              Garantieschäden.
            </p>
            <p class="mb-1.5">
              &hellip; Schäden durch Vorsatz des Versicherungsnehmers.
            </p>
            <p class="mb-1.5">
              &hellip; Schäden durch Kriegsereignisse und innere Unruhen.
            </p>
            <p class="mb-1.5">
              &hellip; Schäden durch Kernenergie.
            </p>
            <p class="mb-1.5">
              &hellip; Schäden, die während der Dauer von Erdbeben als deren Folge entstehen.
            </p>
          </div>
        </div>

        <div class="row mt-7 mb-14">
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis:',
                text: 'Durch die Installation einer Photovoltaikanlage erhöht sich in jedem Fall der Wert ' +
                  'Ihrer Immobilie. Es besteht die Gefahr einer Unterversicherung und sollte daher in jedem Fall ' +
                  'dem Gebäudeversicherer angezeigt werden.'
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis:',
                text: 'Wird die Photovoltaikversicherung als Zusatzbaustein in die Wohngebäudeversicherung ' +
                  'eingeschlossen, ist der Leistungsumfang im Regelfall deutlich geringer, da es sich nicht um eine ' +
                  'Allgefahrendeckung handelt.'
              }]"
            />
          </div>
        </div>

        <h3 class="mb-5 text-center">
          Schadenbeispiel
        </h3>
        <div class="row">
          <div class="col-md-6 col-sm-12 offset-md-3">
            <p class="text-center">
              Infolge eines Sturms, werden die Photovoltaikmodule beschädigt und es kann
              vorübergehend kein Strom ins Netz eingespeist werden.
            </p>
            <List>Die Versicherung übernimmt die Kosten für den entstandenen Sachschaden an den Modulen.</List>
            <List>Zusätzlich werden die entgangenen Einnahmen aus der Einspeisevergütung übernommen.</List>
          </div>
        </div>

        <div class="row mt-7">
          <div class="col-12 col-md-6 pb-7 md:pb-0">
            <NiceToKnow
              classes="mb-0"
              :bullets="[{
                title: 'Hinweis:',
                text: 'Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend. ' +
                  'Sofern einzelne Leistungspunkte für Sie wichtig sind, prüfen wir gerne Sondertarife.'
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="h-full"
              :bullets="[{
                title: 'Hinweis:',
                text: 'Sind Sie Halter eines Hundes oder Pferdes, benötigen Sie eine Tierhalterhaftpflicht. ' +
                  'Über die Privathaftpflicht besteht kein Versicherungsschutz.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import List from '@/application/components/List.vue';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();
</script>
