import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 29 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m27.04 17.5 1.79-.75-.37-.9-1.09-2.53L21.85.26l-1.79.75.38.9-4.63 1.93-.37-.9-1.34.57.37.89-6.08 2.57-.37-.9-1.3.53.37.9-4.63 1.93-.37-.9-1.8.76.38.89 3.7 8.69-1.31.56 1.71 4.1 1.3-.56 1.2 2.8 1.8-.75-.38-.9 4.62-1.93.38.9 1.34-.56-.37-.9 6.08-2.57.37.9 1.34-.57-.37-.9 4.62-1.93.34.93zM7.91 22.4 3.2 11.23l4.62-1.94 4.7 11.19-4.62 1.93zm5.96-2.53L9.17 8.69l6.08-2.57 4.7 11.19-6.08 2.57zm7.39-3.1L16.56 5.6l4.63-1.93 3.17 7.45 1.56 3.7-4.66 1.97zM1.46 29.65c0 1.23.97 2.2 2.2 2.2s2.2-.97 2.2-2.2-2.2-5.9-2.2-5.9-2.2 4.7-2.2 5.9" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }