<script lang="ts" setup>
interface Props {
  title?: string;
}

defineProps<Props>();
</script>

<template>
  <div class="bg-white rounded-t-2xl flex flex-col pointer-events-auto overflow-hidden w-full shadow-2xl">
    <slot name="title">
      <div v-if="title" class="pt-5 font-semibold text-xl px-6">
        {{ title }}
      </div>
    </slot>
    <div class="overflow-auto grow shrink flex flex-col">
      <slot />
    </div>
  </div>
</template>
