<template>
  <DsModal
    :show="show"
    hide-buttons
    title="Vertrag bearbeiten"
    @cancel="$emit('close')"
  >
    <ModalWrapper>
      <UpdateContract
        style="max-height: calc(100dvh - 7rem - env(safe-area-inset-top) - env(safe-area-inset-bottom))"
        :contract="contract"
        @close="$emit('close')"
      />
    </ModalWrapper>
  </DsModal>
</template>

<script setup lang="ts">
import { DsModal } from '@demvsystems/design-components';

import UpdateContract from '@/contracts/components/UpdateContract.vue';
import ModalWrapper from "@/application/components/ModalWrapper.vue";
import type { Contract } from "@/api/contracts/types";

interface Props {
  show: boolean;
  contract: Contract
}

defineProps<Props>();
defineEmits(['close']);
</script>
