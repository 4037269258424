<template>
  <CompanyIcon
    :id="id"
    :max-height="maxHeight"
  >
    <div
      v-if="name"
      :title="name"
    >
      <div class="text-sm text-muted truncate">
        {{ name }}
      </div>
    </div>
  </CompanyIcon>
</template>

<script lang="ts" setup>
import CompanyIcon from '@/application/components/CompanyIcon.vue';

interface Props {
  id: number,
  name?: string,
  maxHeight?: number
}

defineProps<Props>();
</script>
