import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M50.1.9C22.8.9.6 23.1.6 50.4S22.7 99.8 50 99.8s49.4-22.1 49.4-49.4S77.4.9 50.1.9m33.2 76.9-65.8-.5.6-60.4 65.8.5z",
      style: {"fill":"#fff"}
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "m48.5 45.8.3-25.2h3.6l-.2 25.2 27.2.2.2-25.2-57.1-.4-.3 26.7.1-1.5zm16.2 28.3 12-8.3-6.2-2.1 9.1-3.9-.3-5.8.1-4.9-27.3-.2-.2 25.2-3.7-.1.3-25.2-26.3-.2-.2 25.2zM22.2 48.6v-1.5zm57.2.5V46z",
      style: {"fill":"#fff"}
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "M48.5 45.8v3l3.6.1.1-3.1z",
      style: {"fill":"#fff"}
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }