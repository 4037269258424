import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.89"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "49.44",
      cy: "49.44",
      r: "49.44",
      fill: "#fff"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#343a40",
      d: "M78.75 47.33 49.24 28.49l-.01-.03L19.7 47.31l1.77 6.63 3.09-1.97v26.2h49.33V51.99l3.09 1.97zM39.91 14.37l-.52 7.2-6.41.7 3.52 11.32L25.58 18.6l8.95-1.71-.13-8.69 5.81.2z"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }