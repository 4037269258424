<script setup lang="ts">
import { DsSkeleton } from "@demvsystems/design-components";

</script>

<template>
  <div class="flex flex-col gap-5">
    <DsSkeleton gradient class="w-full h-12" />
    <DsSkeleton gradient class="w-full h-12" />
    <DsSkeleton gradient class="w-full h-12" />
    <DsSkeleton gradient class="w-full h-12" />
  </div>
</template>
