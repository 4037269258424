<template>
  <div :class="{ 'text-muted': mute, 'text-secondary': highlight }">
    <div
      v-if="title"
      class="mt-5"
    >
      <strong v-text="title" />
    </div>
    <div class="flex items-start mt-1.5 mb-2.5">
      <DsIcon
        :name="icon"
        size="xs"
        class="w-3 h-3 p-1 rounded-full text-blue-700 bg-blue-100 mr-3 shrink-0"
      />
      <div class="media-body">
        <div v-if="subTitle">
          <strong v-text="subTitle" />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DsIcon } from '@demvsystems/design-components';

interface Props {
  title?: string | null,
  subTitle?: string | null,
  icon?: string,
  highlight?: boolean,
  mute?: boolean
}

withDefaults(defineProps<Props>(), {
  icon: 'arrow-right',
  title: null,
  subTitle: null,
});
</script>
