<template>
  <SpartenWrapper
    image="images/bedarfsinfo/unfallversicherung/sturz"
    text="Jedes Jahr ereignen sich Millionen von Unfälle in Deutschland.
          Die meisten verlaufen glücklicherweise glimpﬂich und ohne bleibende
          Schäden. Jetzt bekommen Sie einen Überblick, ob Sie eine private
          Unfallversicherung benötigen."
    :versorgungen="data.bedarfsinfo.versorgungen"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie bin ich aktuell bei einem Unfall geschützt?"
    versorgungen-description="Unfallversicherung"
  >
    <section class="section">
      <Versicherungsschutz
        :kind="data.bedarfsinfo.kindMitBedarf"
        :taetigkeits-id="data.bedarfsinfo.taetigkeitsId"
        :taetigkeits-name="data.bedarfsinfo.taetigkeitsName"
      />

      <div class="container mt-14">
        <div class="row text-center justify-center">
          <div class="col-sm-12 col-md-10">
            <h3 class="mb-7">
              Worauf muss ich bei der Versicherung achten?
            </h3>
            <p>
              Je schwerer der Unfall, umso höher sind die zu kompensierenden Kosten. Mit einer Progression
              steigert sich die Versicherungssumme, um Sie bestmöglich zu schützen.
            </p>
            <p class="mt-7">
              <strong>Beispiele für Auszahlungen bei vollständigem Funktionsverlust</strong>
            </p>
          </div>
        </div>

        <Auszahlung />

        <div class="row">
          <div class="col">
            <div
              class="flex flex-col sm:flex-row gap-2 sm:gap-5 p-5 hyphens-auto rounded-xl mb-5 bg-blue-500 bg-opacity-5
    border-2 border-blue-500"
            >
              <div class="text-center">
                <SvgVue
                  class="inline-block fill-blue-500 h-10 w-10"
                  icon="bedarfsinfo/nice-to-know"
                />
              </div>
              <div class="flex-initial">
                <p class="mb-0">
                  <b>Hinweis</b><br>
                </p>
                <div class="flex flex-col md:flex-row justify-center items-center gap-x-5">
                  <div>
                    Die Unfallversicherung leistet nur, sofern die körperliche Beeinträchtigung
                    dauerhaft ist und durch einen Unfall verursacht wurde. Als dauerhaft gilt
                    eine Gesundheits&shy;beeinträchtigung, sofern diese voraussichtlich länger
                    als drei Jahre bestehen wird und keine Aussicht auf Besserung besteht.
                  </div>
                  <div>
                    <div class="flex justify-center items-center">
                      <i
                        class="fas fa fa-arrow-right fa-2x hidden md:block"
                        style="color: #2360B0"
                      />
                      <i
                        class="fas fa fa-arrow-down fa-2x block md:hidden my-5 md:my-0"
                        style="color: #2360B0"
                      />
                    </div>
                  </div>
                  <div>
                    Die Berufsunfähigkeitsversicherung leistet hingegen unabhängig von der Ursache
                    der körperlichen Beeinträchtigung und im Regelfall auch bei vorübergehender
                    Arbeitsunfähigkeit. Sofern keine Vorerkrankungen vorliegen und die
                    Berufsunfähigkeitsversicherung bezahlbar ist, ist diese der Unfallversicherung
                    vorzuziehen.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <JumboImage image="images/bedarfsinfo/unfallversicherung/mann-am-boden" />

    <section class="section">
      <div class="container">
        <div class="row text-center justify-center">
          <div class="col-sm-12 col-md-10">
            <h3 class="mb-7">
              Um welche Zusatzleistungen kann ich den Versicherungsschutz erweitern?
            </h3>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col">
            <div class="row mt-5 mb-7 justify-center">
              <template
                v-for="(icon, title) in {
                  'Krankenhaustagegeld': 'krankenhaustagegeld.svg',
                  'Kosmetische Operationen': 'kosmetische-operationen.svg',
                  'Kurkostenbeihilfe': 'kurkostenbeihilfe.svg',
                  'Sofortige Zahlung bei Schwerverletzten': 'schwerverletzten-zahlung.svg',
                  'Bergungskosten': 'bergungskosten.svg',
                  'Todesfallleistung': 'todesfallleistung.svg',
                  'Krankentagegeld': 'krankentagegeld.svg',
                  'Monatliche Unfallrente': 'unfallrente.svg',
                  'Übergangsgeld': 'uebergangsgeld.svg',
                }"
                :key="title"
              >
                <div class="flex flex-col items-center text-center w-44 m-4">
                  <SvgVue
                    :icon="`assets/svg/bedarfsinfo/unfallversicherung/${icon}`"
                    class="img-fluid mb-5"
                    style="max-width: 90px;"
                  />
                  <p class="font-semibold">
                    {{ title }}
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis',
                text: 'Die Todesfallsumme hat in der Unfallversicherung eine wichtige Funktion: Der Versicherte' +
                  ' kann einen Vorschuss bis zur Höhe der Todesfallsumme erhalten, bis das Ausmaß der ' +
                  'Beeinträchtigung feststeht.'
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis',
                text: 'Die Unfallrente leistet im Regelfall erst ab einem Invaliditätsgrad von 50 Prozent. Bei ' +
                  'einer Einmalzahlung erfolgt die Leistung hingegen häuﬁg bereits ab einem Invaliditätsgrad von' +
                  ' einem Prozent.'
              }]"
            />
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-sm-12 col-lg-7">
            <h3 class="mb-5">
              Wann erhalte ich welche Leistungen?
            </h3>
            <p class="">
              <span class="text-secondary">
                <i><strong>“Ein Unfall liegt vor, wenn die versicherte Person durch ein plötzlich von außen
                  auf ihren Körper wirkendes Ereignis (Unfallereignis) unfreiwillig eine
                  Gesundheitsschädigung erleidet.“ </strong></i>
              </span>
              <small>– §178 Abs. 2 Satz 1 VVG</small>
            </p>
          </div>
        </div>

        <div class="row mt-7">
          <div class="col-sm-12 col-lg-7">
            <p>Jedes einzelne Merkmal ist dabei von Bedeutung:</p>
            <BulletPointList
              :items="[
                {
                  'title': 'Plötzlich',
                  'text': 'Der Zeitraum, in dem der Schaden eintritt, ist kurz und es kommt unmittelbar ' +
                    'zu einer Schädigung der Gesundheit.'
                },
                {
                  'title': 'Von außen auf den Körper wirkend',
                  'text': 'Es wirken mechanische, chemische, thermische oder elektrische Kräfte auf den ' +
                    'Körper ein. Dazu zählt beispielsweise auch ein unbeabsichtigter Sturz, der aus der' +
                    ' eigenen Bewegung heraus entsteht.'
                },
                {
                  'title': 'Ereignis',
                  'text': 'Dies kann durch menschliches Handeln (stoßen, schlagen, fallen) oder eine ' +
                    'Naturgewalt (Wind, Feuer, Eis) ausgelöst werden.'
                },
                {
                  'title': 'Unfreiwillig',
                  'text': 'Absichtlich herbei geführte Schädigungen sind ausgeschlossen, nicht aber ' +
                    'Verletzungen aufgrund von Notwehr oder fahrlässigem Handeln.'
                },
                {
                  'title': 'Gesundheitsschäden',
                  'text': 'Damit sind ärztlich festzustellende körperliche Beeinträchtigungen gemeint.'
                },
              ]"
            />

            <NiceToKnow
              classes="mt-7"
              :bullets="[{ title: 'Hinweis',
                           text: 'Die Auszahlung der Invaliditätssumme aus einer Unfallversicherung erfolgt ' +
                             'steuerfrei. Die Unfallrente hingegen wird mit dem Ertragsanteil versteuert und ' +
                             'Lohnersatzleistungen (Ausfall für entgangenen Lohn) unterliegen der' +
                             ' Einkommenssteuer.'
              }]"
            />
          </div>
          <div class="col-sm-12 col-lg-5 text-center hidden lg:block">
            <img
              src="../../../../../../assets/images/bedarfsinfo/unfallversicherung/arzt.jpg"
              class="img-fluid"
              alt=""
            >
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import JumboImage from '@/bedarf/components/JumboImage.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import Auszahlung from '@/bedarf/components/sach/unfall/Auszahlung.vue';
import Versicherungsschutz from '@/bedarf/components/sach/unfall/Versicherungsschutz.vue';
import type { UnfallInfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: UnfallInfo,
}

defineProps<Props>();
</script>
