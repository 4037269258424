<template>
  <div class="text-center">
    <div
      v-for="({ name, size }, index) in files"
      :key="index"
      class="mt-5"
    >
      <span class="text-danger">
        Die Datei "{{ name }}" hat einen nicht erlaubten Dateityp oder ist
        größer als {{ prettyPrintSize(MAX_UPLOAD_SIZE) }} ({{ prettyPrintSize(size) }})
      </span>
    </div>

    <div
      v-for="(errorMsg, index) in errorMessages ?? []"
      :key="index"
      class="mt-5"
    >
      <span class="text-danger">
        {{ errorMsg }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MAX_UPLOAD_SIZE } from '@/documents/composables/documentUpload';
import { prettyPrintSize } from '@/application/utils/prettyPrintSize';

interface Props {
  files: File[],
  errorMessages?: string[],
}

defineProps<Props>();

</script>
