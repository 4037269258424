<template>
  <SpartenWrapper
    image="images/bedarfsinfo/kfz/kfz-titelbild"
    text="Fahren Sie ein Kraftfahrzeug, so verpflichtet Sie der Gesetzgeber
    zum Abschluss einer Kfz-Haftpflichtversicherung.
    Ohne den Nachweis eines bestehenden Versicherungsschutzes,
    können Sie Ihr Fahrzeug nicht zulassen. Zudem können Sie über
    die Teilkasko und Vollkasko auch Schäden am eigenen Fahrzeug versichern."
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie gut ist mein Fahrzeug versichert?"
    versorgungen-description="Kfz-Versicherung"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Welche Bereiche kann ich versichern?</h2>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row items-center my-14 justify-center">
            <div class="col-6 col-md-5 mb-7">
              <SvgVue
                icon="bedarfsinfo/kfz/haftpflicht.svg"
                class="w-full"
              />
            </div>

            <div class="col-md-7">
              <h4>Haftpflicht</h4>
              <p>
                Fügen Sie, infolge der Nutzung des Fahrzeugs, einem Dritten einen Schaden zu,
                sind Sie verpflichtet, den Schaden zu ersetzen - unabhängig von der Schadenhöhe.
              </p>
              <p>
                Die Haftpflichtversicherung übernimmt die entstehenden Kosten
                für Sach-, Personen- und Vermögensschäden bis zur vereinbarten Versicherungssumme.
              </p>
            </div>
          </div>

          <div class="row items-center my-14 flex-col-reverse md:flex-row">
            <div class="col-md-7">
              <div class="row">
                <div class="col-12">
                  <h4>Teilkasko</h4>
                  <p>
                    Kommt es zu Schäden am eigenen Fahrzeug, reguliert die Teilkasko diese, sofern Sie
                    verursacht wurde durch
                  </p>
                </div>

                <div class="col-lg-6">
                  <List>Diebstahl</List>
                  <List>Brand und Explosion</List>
                  <List>Sturm, Blitzschlag, Hagel und Überschwemmung</List>
                  <List>Glasbruch</List>
                </div>

                <div class="col-lg-6">
                  <List>Marderbiss an Kabeln, Schläuchen und Leitungen</List>
                  <List>Kabelschaden durch Kurzschluss</List>
                  <List>Zusammenstöße mit Haarwild (zum Beispiel Reh oder Wildschwein)</List>
                </div>
              </div>
            </div>

            <div class="col-6 col-md-5 mb-7">
              <SvgVue
                icon="bedarfsinfo/kfz/teilkasko.svg"
                class="w-full"
                style=""
              />
            </div>
          </div>

          <div class="row items-center my-14 justify-center">
            <div class="col-6 col-md-5 mb-7">
              <SvgVue
                icon="bedarfsinfo/kfz/vollkasko.svg"
                class="w-full"
                style=""
              />
            </div>

            <div class="col-md-7">
              <h4>Vollkasko</h4>
              <p>
                Mit der Vollkasko werden, zusätzlich zu den Leistungen der Teilkasko,
                auch Schäden am eigenen Fahrzeug erstattet,
                die durch selbst verschuldete Unfälle entstanden sind.
              </p>
              <p>
                <strong>Ebenfalls sind Schäden durch Vandalismus mitversichert.</strong>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row justify-center">
            <div class="col-md-10 col-lg-6 my-7">
              <NiceToKnow
                :bullets="[{
                  title: 'Tipp!',
                  text: 'Ist ein Auto bereits sehr alt und hat nur noch einen geringen Fahrzeugwert, ' +
                    'reicht die KFZ-Haftpflicht-Versicherung meist aus.'
                }]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <h2 class="text-center mb-7">
        Welche Leistungen bieten Top-Tarife?
      </h2>

      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <h4>Grobe Fahrlässigkeit</h4>
                <p>
                  Überfahren Sie versehentlich eine rote Ampel und verursachen infolge dessen einen
                  Unfall,
                  sollte dieses mitversichert sein. Vom Versicherungs&shy;schutz ausgeschlossen sind
                  hingegen
                  Unfälle unter Alkohol- oder Drogen&shy;einfluss sowie Diebstahl des Fahrzeugs aufgrund
                  einer groben Fahrlässig&shy;keit Ihrerseits.
                </p>
              </div>
              <div class="col-12 mt-5">
                <h4>Neuwertentschädigung</h4>
                <p>
                  Kommt es bei einem Neufahrzeug kurz nach Kauf zu einem Totalschaden,
                  wird der ursprüngliche Kaufpreis erstattet und nicht der schnell sinkende Zeitwert.
                </p>
              </div>
              <div class="col-12 mt-5">
                <h4>Marderbiss und Folgeschäden</h4>
                <p>
                  Knabbert ein Marder Schläuche im Motorenraum an,
                  entstehen schnell Kosten im vierstelligen Bereich.
                  Marder haben Ihren Lebensraum übrigens sowohl in
                  der Stadt, als auch auf dem Land.
                </p>
              </div>
              <div class="col-12 mt-5">
                <h4>Rabattschutz</h4>
                <p>
                  Mit einem Rabattschutz werden Sie im Schadenfall in der Schadenfreiheits&shy;klasse
                  nicht zurück gestuft, sondern verbleiben in dieser. Wechseln Sie die Kfz-Versicherung,
                  werden Sie jedoch wieder mit der Schadenfreiheitsklasse
                  eingestuft, die Sie ohne Rabattschutz hätten.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 mt-5 lg:mt-0">
            <div class="row">
              <div class="col-12">
                <h4>Unfälle mit Tieren aller Art</h4>
                <p>
                  Bei Unfällen mit Tieren ist häufig nur Haarwild (Fuchs, Reh, Wildschwein)
                  versichert. Achten Sie auf den Einschluss von Tieren aller Art.
                </p>
              </div>
              <div class="col-12 mt-5">
                <h4>Mallorca-Police</h4>
                <p>
                  Haben Sie im europäischen Ausland einen Haftpflichtschaden, stockt die eigene
                  Versicherung bis zur für Sie in Deutschland geltenden maximalen Versicherungssumme auf.
                </p>
              </div>
              <div class="col-12 mt-5">
                <h4>Verzicht auf Abzug neu für alt</h4>
                <p>
                  Insbesondere bei älteren Fahrzeugen werden im Schadenfall die Kosten für neue
                  Verschleißteile,
                  wie beispielsweise Reifen nicht übernommen. Der Versicherer kann auf diesen Abzug
                  verzichten,
                  so dass die Kosten für alle Ersatzteile übernommen werden.
                </p>
              </div>
              <div class="col-12 mt-5">
                <h4>Schutzbrief</h4>
                <p>
                  Haben Sie eine Panne, werden bei vorhandenem Schutzbrief die Ab&shy;schleppkosten
                  übernommen.
                  Teilweise werden auch notwendige Über&shy;nachtungskosten erstattet oder ein Mietwagen
                  gestellt,
                  bis das eigene Fahrzeug wieder einsatzbereit ist.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Tipp!',
                text: 'Neuwagen haben meist eine Mobilitätsgarantie, so dass der Schutzbrief nicht notwendig ist.'
              }]"
            />
          </div>
          <div class="col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Tipp!',
                text: 'Sind Sie Mitglied in einem Automobilclub, haben Sie darüber meist bessere Leistungen ' +
                  'und benötigen keinen Schutzbrief mehr in Ihrer KFZ-Versicherung.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import List from '@/application/components/List.vue';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();
</script>
