<template>
  <SpartenWrapper
    image="images/bedarfsinfo/rechtsschutz/titelbild"
    text="Kommt es zu einer gerichtlichen Auseinandersetzung,
          entstehen schnell Kosten in Höhe von mehreren tausend Euro oder sogar
          zehntausend Euro. Eine Rechtsschutzversicherung übernimmt
          &ndash; unabhängig vom Ausgang des Verfahrens &ndash; die Gerichts-
          und Anwaltskosten sowie bei Bedarf ein kostenfreies Darlehen
          für eine zu stellende Kaution."
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie gut bin ich bei meiner Rechtsschutzversicherung versichert?"
    versorgungen-description="Rechtsschutzversicherung"
  >
    <section class="section">
      <div class="container">
        <div class="row justify-center">
          <div class="col-xl-10">
            <div class="text-center pb-7">
              <h2>Welche Kosten entstehen bei einem verlorenen Rechtsstreit?</h2>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-lg-10 col-xl-9">
            <RsKosten />
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis',
                text: 'Sofern aus Sicht des Rechtsschutzversicherers keine Möglichkeit besteht, den ' +
                  'Rechtsstreit zu gewinnen, darf der Versicherer die Deckung des Rechtsstreits verweigern.'
              }]"
            />
          </div>
          <div class="col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis',
                text: 'Sind Sie mit der Ablehnung des Rechtsschutzes nicht einverstanden, können Sie ' +
                  'beim Ombudsmann für Versiche&shy;rungen Beschwerde einlegen. Dieser agiert für Verbraucher' +
                  ' kostenfrei und kann Versicherer bis zu einer Leistung von 10.000 € verpflichten. ' +
                  'Alternativ kann Ihr Anwalt die Entscheidung anfechten (Stichentscheid) oder ein ' +
                  'Schieds&shy:gutachten angefordert werden.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row justify-center">
          <div class="col-lg-10">
            <div class="text-center">
              <h2>Welche Bereiche kann ich unter anderem versichern?</h2>
              <p>
                Die Rechtsschutzversicherung lässt sich mittels der folgenden Bausteine passgenau zusammen
                stellen.
              </p>
            </div>
          </div>
        </div>

        <div class="row mb-7 justify-center">
          <div class="col-xl-10">
            <div class="row">
              <div class="col-md-6">
                <div class="my-7">
                  <List>
                    <strong>Privatrechtsschutz</strong><br>
                    z.B. um Rentenansprüche gegenüber Sozialversicherungs&shy;trägern durchzusetzen
                  </List>
                </div>
                <div class="my-7">
                  <List>
                    <strong>Berufsrechtsschutz</strong><br>
                    z.B. um eine Abfindung aus einer unberechtigten Kündigung durchzusetzen
                  </List>
                </div>
                <div class="my-7">
                  <List>
                    <strong>Verkehrsrechtsschutz</strong><br>
                    z.B. um gegen ein verhängtes Fahrverbot vorzugehen
                  </List>
                </div>
                <div class="my-7">
                  <List>
                    <strong>Erweiterter Strafrechtsschutz</strong><br>
                    z.B. um sich gegenüber Ermittlungen im Zuge eines Strafver&shy;fahrens zu schützen
                  </List>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-7 md:mt-4">
                  <List>
                    <strong>Rechtsschutz für Mieter</strong><br>
                    z.B. um eine Mietkürzung während durchgeführter Baumaß&shy;nahmen durchzusetzen
                  </List>
                </div>
                <div class="my-7">
                  <List>
                    <strong>Rechtsschutz für Vermieter</strong><br>
                    z.B. um Mietnomaden los zu werden
                  </List>
                </div>
                <div class="my-7">
                  <List>
                    <strong>Rechtsschutz für Eigentümer von Wohnungen und Grundstücken</strong><br>
                    z.B. um Streitigkeiten mit Nachbarn gerichtlich zu klären
                  </List>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis',
                text: 'Haben Sie innerhalb kurzer Zeit zwei oder mehr Rechtsschutzfälle, kann Ihnen der ' +
                  'Versicherer vorzeitig kündigen. Die Versichertengemeinschaft soll so vor besonders ' +
                  'streitlustigen Personen geschützt und die Beiträge stabil gehalten werden.'
              }]"
            />
          </div>
          <div class="col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis',
                text: 'Die Berufsrechtsschutz ist besonders zu empfehlen, da anders als im Zivilrecht, ' +
                  'in der ersten Instanz beide Parteien für Ihre Anwaltskosten selbst aufkommen müssen. ' +
                  'Zudem ist die Berufsrechtsschutzversicherung im Rahmen der Einkommensteuererklärung ' +
                  'absetzbar.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <JumboImage
      image="images/bedarfsinfo/rechtsschutz/freundliches-gespraech"
      alt="Ein freundliches Gespräch mit einem Rechtsexperten"
    />

    <section class="section">
      <div class="container">
        <div class="row justify-center">
          <div class="col-lg-10">
            <div class="text-center">
              <h2>Wann habe ich keinen Versicherungsschutz?</h2>
            </div>
          </div>
        </div>

        <div class="row justify-center my-7">
          <div class="col-lg-8">
            <div class="row">
              <div class="col-md-6">
                <List>Vorsätzlich begangene Straftaten</List>
                <List>spekulative Kapitalanlagen sowie Spiel- und Wettverträge</List>
                <List>Streit zwischen gemeinsam in einer Police versicherten Personen</List>
                <List>Streitigkeiten vor Verfassungsgerichten bzw. vor internationalen Gerichtshöfen</List>
              </div>
              <div class="col-md-6">
                <List>Insolvenzverfahren</List>
                <List>Enteignungs-, Flurbereinigungs- oder Planfest&shy;stellungsverfahren</List>
                <List>Ansprüche gegen den Rechtsschutzversicherer selbst</List>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis',
                text: 'Es gibt spezielle Anbieter für den Bereich von Sorgerechts, Scheidungs, ' +
                  'Urheber-/Patent- und Baurecht.<br><br>Bitte teilen Sie uns mit, falls Sie den ' +
                  'Einschluss wünschen.'
              }]"
            />
          </div>
          <div class="col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis',
                text: 'Diese Aufzählung beinhaltet wesentliche Leistungsaus- schlüsse und ist nicht ' +
                  'abschließend. Zudem besteht im Regelfall eine Wartezeit, bevor erstmalig Leistungen' +
                  ' in Anspruch genommen werden können.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light text-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="text-center pb-7">
              <h2>Wer ist versichert?</h2>
            </div>
            <p>
              Je nach Vereinbarung können folgende Personen versichert werden:
            </p>
          </div>
        </div>

        <div
          class="flex flex-col items-center justify-center md:flex-row md:items-start gap-8
          mt-5"
        >
          <div class="flex flex-col items-center w-60">
            <SvgVue
              icon="bedarfsinfo/rechtsschutz/versicherungsnehmer.svg"
              class="mb-7"
              style="max-width: 120px;"
            />
            <h4>Versicherungsnehmer</h4>
          </div>
          <div class="flex flex-col items-center w-60">
            <SvgVue
              icon="bedarfsinfo/rechtsschutz/ehe-lebenspartner.svg"
              class="mb-7"
              style="max-width: 120px;"
            />
            <h4>Ehe- bzw. Lebenspartner</h4>
          </div>
          <div class="flex flex-col items-center w-60">
            <SvgVue
              icon="bedarfsinfo/rechtsschutz/sonstige-personen.svg"
              class="mb-7"
              style="max-width: 120px;"
            />
            <h4>Sonstige Personen</h4>
            Eltern z.B. im Pflegeheim, unverheiratete Kinder, &hellip;
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="text-center pb-7">
              <h2>Schadenbeispiele</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <p>Ein Arbeitnehmer hat die Kündigung erhalten und klagt auf Wiedereinstellung.</p>

            <List>Es werden die Kosten des eigenen Anwalts übernommen.</List>
            <List>Es erfolgt die Kostenübernahme des gegnerischen Anwalts.</List>
            <List>Es werden die Gerichtskosten übernommen.</List>
          </div>
          <div class="col-md-6 mt-5 md:mt-0">
            <p>
              Der Kunde kauft einen Gebrauchtwagen und bereits nach kurzer Zeit
              stellt sich ein Motorschaden heraus. Der Kunde verlangt daher
              die Rückabwick- lung des Kaufvertrages.
            </p>

            <List>Es werden die Kosten des eigenen Anwalts übernommen.</List>
            <List>Es erfolgt die Kostenübernahme des gegnerischen Anwalts.</List>
            <List>Es werden die Gerichtskosten übernommen.</List>
            <List>Es erfolgt die Kostenübernahme für einen Sachverständigen.</List>
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import JumboImage from '@/bedarf/components/JumboImage.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import RsKosten from '@/bedarf/components/sach/rechtsschutz/KostenRechtsstreit.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import List from '@/application/components/List.vue';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();
</script>
