<script setup lang="ts">
import { DsSkeleton } from '@demvsystems/design-components';

</script>

<template>
  <div class="flex flex-col gap-5 p-4">
    <DsSkeleton gradient class="h-20" />
    <DsSkeleton gradient class="h-44" />
    <DsSkeleton gradient class="h-12" />
  </div>
</template>
