import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1416 1176"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "710.6",
      cy: "593.3",
      r: "568",
      fill: "#4181D2"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M715.7 296c41 0 74.2-33.2 74.2-74.2s-33.2-74.2-74.2-74.2-74.2 33.2-74.2 74.2 33.2 74.2 74.2 74.2m197.1 748.9-64.6-385.8.8-.5 10.9-35.7c2.5.1-15.4-107.6-18.4-161.4-6.7-120.5-53-149.1-53.7-149.5l-74.1-2.1s-66.6-9.6-82.3 0l-88.5 126 80.8 93C609.2 707 582.4 950 579.6 975.1c-1.6.7-39.5 17-41.2 35-1.8 18.4 4.9 34.2 4.9 34.2l56.4-10.5 29.8-24.1-.7-34.4L698.7 647l8.3-.8 88.2 331.2h.1l-.7 34.6 29.8 24.1 56.4 10.5s2.1-5.1 3.6-12.8l2.6 15.6c1.1 6.3 6.6 10.8 12.8 10.8.7 0 1.4-.1 2.2-.2 7.3-1.3 12-8 10.8-15.1M627 473.2l-39.2-41.1 44.7-41.4c-.7 25.2-2.7 44.9-5.5 82.5m217.6 504c-73.7-259.1-60.2-492.2-50.1-585.1l12.7 71 15 140.8.6 2.4c-5.1 2.4-8.2 8-7.3 13.8l63.4 379.1c-11.9-12.5-34.3-22-34.3-22"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }