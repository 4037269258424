<script setup lang="ts">
import { DsSkeleton } from "@demvsystems/design-components";
</script>

<template>
  <div class="flex flex-col justify-end gap-8 mt-5">
    <DsSkeleton gradient class="w-full h-10" />
    <DsSkeleton gradient class="w-full h-10" />
    <DsSkeleton gradient class="w-full h-10" />
    <DsSkeleton gradient class="w-full h-10" />
    <DsSkeleton gradient class="w-full h-10" />
    <div class="flex flex-row justify-end mt-5">
      <DsSkeleton gradient class="w-full sm:w-1/3 h-10" />
    </div>
  </div>
</template>
