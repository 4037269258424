<template>
  <DsAccordion
    :model-value="open"
    trigger-full-width
  >
    <template #trigger="{ open: isOpen }">
      <div class="flex flex-row items-center justify-start gap-2 my-2">
        <DsIcon
          name="chevron-down"
          size="sm"
          class="text-secondary"
          :rotation="isOpen ? '180' : undefined"
        />
        <h5 class="mb-0">
          {{ name }}
        </h5>
      </div>
    </template>
    <template #default>
      <div class="row mx-0 mt-2.5 mb-5">
        <div class="col-12 px-0 col-md-6">
          <h6
            class="font-bold mb-0"
            v-text="headline"
          />
          <p
            class="mb-0"
            v-text="text"
          />
        </div>
        <div class="col-12 px-0 col-md-6 flex items-center justify-center">
          <SvgVue
            :icon="img"
            class="img-fluid h-full"
          />
        </div>
      </div>
      <div
        v-if="tableEntries.length > 0"
        class="table-responsive"
      >
        <table class="table Table mt-7 lg:mt-0">
          <thead>
            <tr
              :class="$style.colWidths"
            >
              <th>
                <div>Versicherer</div>
                <div>Produktbezeichnung</div>
              </th>
              <th>Kapitalanlage</th>
              <th>Einflussmöglichkeit des Kunden</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="({
                versicherer,
                produktbezeichnung,
                kapitalanlage,
                einflussKunde,
              }, idx) in tableEntries"
              :key="idx"
              :class="$style.colWidths"
            >
              <td>
                {{ versicherer }} <br>
                {{ produktbezeichnung }} <br>
              </td>
              <td>
                <template v-if="kapitalanlage.length < 150">
                  {{ kapitalanlage }}
                </template>
                <ShowMoreWrapper
                  v-else
                  :text="kapitalanlage"
                  :arrow-color="$style.arrowColor"
                />
              </td>
              <td>
                <template v-if="einflussKunde.length < 150">
                  {{ einflussKunde }}
                </template>
                <ShowMoreWrapper
                  v-else
                  :text="einflussKunde"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </DsAccordion>
</template>

<script setup lang="ts">
import { DsAccordion, DsIcon } from '@demvsystems/design-components';
import ShowMoreWrapper from '@/bedarf/components/altersvorsorge/altersvorsorge/finder/ShowMoreWrapper.vue';
import SvgVue from "@/application/components/SvgVue";
import type { TableEntry } from "@/bedarf/components/altersvorsorge/altersvorsorge/finder/types";

interface Props {
  name: string;
  headline: string;
  text: string;
  img: string;
  imgAlt: string;
  tableEntries: TableEntry[];
  open: boolean;
}

defineProps<Props>();
</script>

<style lang="scss" module>
$white: #FFF;
$black: #000;
$blue: #4181D2;
$blue-light: #D8E7F7;
$light: #F2F2F5;

.arrowColor {
  color: $blue;
}

.zebraStripesLight {
  :nth-child(odd) {
    background-color: $light;
  }
}

.colWidths {
  > :nth-child(1) {
    width: 20%;
  }

  > :nth-child(2) {
    width: 40% !important;
  }

  > :nth-child(3) {
    width: 40% !important;
  }
}
</style>
