<template>
  <SpartenWrapper
    image="images/bedarfsinfo/tierhalterhaftpflicht/tierhalterhaftpflicht"
    text="Halten oder hüten Sie Hunde oder Pferde, so sind Sie verpflichtet, mögliche Schäden,
          die durch die Tiere entstehen, zu ersetzen — unabhängig von der Schadenhöhe.
          Eine Tierhalterhaftpflicht nimmt Ihnen das finanzielle Risiko ab."
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie gut bin ich als Tierhalter versichert?"
    versorgungen-description="Tierhalterhaftpflicht"
  >
    <section class="section">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <h3 class="mb-7">
              Wann leistet die Tierhalterhaftpflicht?
            </h3>
            <p>
              Die Versicherung übernimmt die Haftung auch für den Fall, dass Ihnen kein Vorwurf bzw. Verschulden
              für den durch das Tier entstandenen Schaden nachgewiesen werden kann.
            </p>
          </div>
        </div>
        <div class="row mt-7">
          <div class="col-lg-4 mb-5 md:mb-0">
            <div
              class="flex items-end justify-center mb-5"
              style="height: 276px;"
            >
              <img
                src="../../../../../../assets/images/bedarfsinfo/tierhalterhaftpflicht/hund-sachschaden.jpg"
                alt="Ein liegender kleiner Hund"
              >
            </div>

            <h5 class="text-center">
              Sachschaden
            </h5>
            <p>
              Im Spieltrieb beschädigt der junge Welpe Teile des Wohnzimmers des Nachbarn.
            </p>
          </div>

          <div class="col-lg-4 mb-5 md:mb-0">
            <div
              class="flex items-end justify-center mb-5"
              style="height: 276px;"
            >
              <img
                src="../../../../../../assets/images/bedarfsinfo/tierhalterhaftpflicht/hund-personenschaden.jpg"
                alt="Ein sitzender kleiner Hund"
              >
            </div>

            <h5 class="text-center">
              Personenschaden
            </h5>
            <p>
              Der Wachhund beißt den neuen Versicherungs&shy;vertreter, da er nur dem langjährigen
              Versicherungs&shy;makler vertraut. Die Tierhalter&shy;haftpflicht übernimmt die
              Behandlungs- und Folgekosten und &hellip;
            </p>
          </div>

          <div class="col-lg-4 mb-5 md:mb-0">
            <div
              class="flex items-end justify-center mb-5"
              style="height: 276px;"
            >
              <img
                src="../../../../../../assets/images/bedarfsinfo/tierhalterhaftpflicht/hund-vermoegensschaden.jpg"
                alt="Ein sitzender Hund"
              >
            </div>

            <h5 class="text-center">
              Vermögensschaden
            </h5>
            <p>
              &hellip; darüber hinaus das Schmerzensgeld sowie die Kosten für den Verdienstausfall
              der verletzten Person.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="[{
                title: 'Hinweis:',
                text: 'Schäden von zahmen Kleintieren wie Katzen oder Hamstern, sind über die ' +
                  'Privathaft&shy;pflicht versichert.'
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="[{
                title: 'Hinweis:',
                text: 'Die Versicherung weist unberechtigte Forderungen ab und setzt dieses gerichtlich ' +
                  'durch (sogenannter passiver Rechtsschutz).'
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <h3 class="mb-5">
              Welche zusätzlichen Leistungen bieten Top-Tarife?
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <List title="Hunde">
              Verzicht auf Leinen- und Maulkorbzwang
            </List>
            <List>Private Tierhüter sind mitversichert</List>
            <List>Beitragsfreie Mitversicherung von Welpen im 1. Lebensjahr</List>
          </div>
          <div class="col-md-6">
            <List title="Pferde">
              Mitversicherung von Reitbeteiligungen und Fremdreitern
            </List>
            <List>Schäden an gemieteten Stallungen, Reithallen, Weiden und Pferdetransportanhängern</List>
            <List>Beitragsfreie Mitversicherung von Fohlen im 1. Lebensjahr</List>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <List title="Gilt für Hunde und Pferde">
              Ausfalldeckung mit Rechtsschutz<br>Kann der Schuldige für den entstanden Schaden nicht
              aufkommen, werden die Kosten von der eigenen Versicherung erstattet.
            </List>
            <List>Marktgarantie<br>Es besteht mindestens der Versicherungsumfang, den andere Versicherer bieten.</List>
            <List>Innovationsgarantie<br>Künftige Leistungsverbesserungen selten auch für Bestandskunden.</List>
          </div>
          <div class="col-md-6">
            <img
              src="../../../../../../assets/images/bedarfsinfo/tierhalterhaftpflicht/hund-pferd.jpg"
              class="hidden md:inline-block"
              alt="Ein Pferd auf einer Weide - ein Hund sitzt fröhlich daneben auf einem Zaun"
            >
          </div>
        </div>

        <div class="row mt-7">
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis:',
                text: 'In einigen Bundesländern ist die Tierhalterhaftpflicht eine Pflichtversicherung. ' +
                  'Dies ist teilweise abhängig von der Rasse oder Gemeinde.'
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis:',
                text: 'Im Falle eines Schadens muss dieser der Versicherung unmittelbar, (meist eine Woche) ' +
                  'nach Eintritt des Ereignisses, angezeigt werden.'
              }]"
            />
          </div>
        </div>

        <div class="row text-center mt-14">
          <div class="col-12">
            <h3 class="mb-7">
              Wann habe ich keinen Versicherungsschutz?
            </h3>
          </div>
        </div>
        <div class="row mt-2.5">
          <div class="col-md-6 mx-auto">
            <List>Bei Eigenschäden, die Sie sich selbst oder einer mitversicherten Person zufügen</List>
            <List>Bei vorsätzlich herbeigeführten Schäden</List>
            <List>Bei vertraglicher Haftung</List>
            <List>Bei beruflicher oder gewerblicher Nutzung des Tieres</List>
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import List from '@/application/components/List.vue';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();
</script>
