<template>
  <div :class="$style.licensePlate">
    {{ licensePlate.replace(/-/g, ' ') }}
  </div>
</template>

<script setup lang="ts">
interface Props {
  licensePlate: string,
}
defineProps<Props>();
</script>

<style lang="css" module>
.licensePlate {
  display: inline-block;
  border: 1px solid #333;
  border-radius: .25rem;
  font-weight: 500;
  padding: .2rem .2rem .2rem 1rem;
  line-height: 1;
  position: relative;
  white-space: nowrap;
  background: white;
}
.licensePlate::before {
  background-color: #337ab7;
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 0.7rem;
}
</style>
