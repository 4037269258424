import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 93.33 93.33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "46.67",
      cy: "46.67",
      r: "46.67",
      fill: "#4181D2"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M85.62 32c-4.5 0-8.46 2.91-10.73 7.28a9.47 9.47 0 0 0-6.52-2.61h-10a9.5 9.5 0 0 0-9.5 9.5v1.17h-4.33v-1.17a9.5 9.5 0 0 0-9.5-9.5h-10a9.46 9.46 0 0 0-6.61 2.69C16.16 35 12.21 32.09 7.7 32.09a2.5 2.5 0 0 0 0 5c4.27 0 7.75 4.82 7.75 10.75 0 .21.03.41.08.61v3.39a9.5 9.5 0 0 0 9.5 9.5h10a9.5 9.5 0 0 0 9.5-9.5v-.5h4.33v.5a9.5 9.5 0 0 0 9.5 9.5h10a9.5 9.5 0 0 0 9.5-9.5v-4.08c0-5.93 3.48-10.75 7.75-10.75a2.5 2.5 0 0 0 .01-5.01M39.54 51.83a4.5 4.5 0 0 1-4.5 4.5h-10a4.5 4.5 0 0 1-4.5-4.5v-5.67a4.5 4.5 0 0 1 4.5-4.5h10a4.5 4.5 0 0 1 4.5 4.5zm33.33 0a4.5 4.5 0 0 1-4.5 4.5h-10a4.5 4.5 0 0 1-4.5-4.5v-5.67a4.5 4.5 0 0 1 4.5-4.5h10a4.5 4.5 0 0 1 4.5 4.5z"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }