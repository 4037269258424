import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M21.4 9.54V7.68a3.43 3.43 0 0 0-3.42-3.42h-3.96a3.43 3.43 0 0 0-3.43 3.42v1.86h1.76V7.68c0-.93.74-1.66 1.67-1.66h3.96c.93 0 1.67.73 1.67 1.66v1.86h1.76zm6.86 6.33c-1.83.8-6.18 2.66-9.48 3.52v1.44c0 .67-.54 1.22-1.21 1.22h-3.14c-.67 0-1.21-.55-1.21-1.22V19.4a74.5 74.5 0 0 1-9.48-3.52v11.87H28.3V15.87z" }, null, -1 /* HOISTED */),
    _createElementVNode("path", { d: "M13.22 17.7c0-.68.54-1.22 1.21-1.22h3.14c.67 0 1.21.54 1.21 1.22v.48a79 79 0 0 0 9.48-3.62v-3.74H3.74v3.74c1.28.58 6.02 2.62 9.48 3.62z" }, null, -1 /* HOISTED */),
    _createElementVNode("path", { d: "M14.43 17.66c-.03 0-.03.04-.03.04v3.13c0 .03.03.03.03.03h3.14c.03 0 .03-.03.03-.03V17.7c0-.04-.03-.04-.03-.04z" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }