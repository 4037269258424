import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M49.9.3C22.6.3.4 22.5.4 49.7s22.1 49.4 49.4 49.4S99.3 77 99.3 49.7 77.1.3 49.9.3m18.3 27.1c5.5 0 10 4.5 10 10s-4.5 10-10 10-10-4.5-10-10 4.5-10 10-10m-28.9-1.8c6 0 10.8 4.8 10.8 10.8s-4.8 10.8-10.8 10.8-10.8-4.8-10.8-10.8c0-5.9 4.8-10.8 10.8-10.8m16.2 49c-16.9.4-21.5.3-36.5-.1C19.1 61 28.1 50 39.3 50c7.6 0 14.2 5 17.7 12.5-.9 3.6-1.4 7.7-1.5 12.1m1.7.1c0-14.3 5.2-25.8 11.7-25.8s11.7 11.6 11.7 25.8c-11.7.5-14.1.4-23.4 0"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }